import { useState, useEffect } from 'react';
import { isEmpty, isBoolean } from 'lodash';

const getSavedData = (key, initialValue) => {
  const savedData = JSON.parse(localStorage.getItem(key));

  if (!isEmpty(savedData) || isBoolean(savedData)) return savedData;

  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

const usePersistState = (key, initialValue) => {
  const [value, setValue] = useState(() => getSavedData(key, initialValue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default usePersistState;
