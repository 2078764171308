import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export default styled(Table)`
  font-size: 15px;
  background: #fff;
  border-radius: 10px;

  > thead {
    /* border-bottom: 1px solid gray; */
    font-size: 0.8em;
    font-family: 'Lato';
    color: rgba(0, 0, 0, 0.82);
    /* border-bottom-width: 2px; */

    th {
      vertical-align: inherit;
      border-top: 0;
      /* font-weight: normal !important; */
      padding: 1.5em 2.5em;

      /* border-color: #f1f1f6 !important; */
    }
  }

  > tbody {
    /* border: 0; */
    /* border-bottom: 1px solid #dee2e6; */
    font-size: 0.8em;
    td {
      /* border: 0; */
      padding-top: 15px;
      padding-bottom: 15px;
      padding: 1.5em 2.5em;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  > tbody + tbody {
    /* border: 0;
    border-bottom: 1px solid #dee2e6; */
  }
`;
