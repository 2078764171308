import { useQuery, useMutation } from 'react-query';
import { handleError } from 'Helper/utils';
import { filter, map } from 'lodash';
import Axios from 'axios';

import { Request } from './request';

export const useLogout = (CB) =>
  useQuery('Logout', () => Request.post(`tenants/logout/`), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    enabled: false,
    onError: (err) => handleError(err?.response?.data?.error?.message),
    select: (data) => data,
    onSuccess: () => {
      localStorage.clear();
      sessionStorage.clear();
      CB(true);
    },
    onError: () => {
      CB(false);
    },
  });

export const useRolesData = () =>
  useQuery('Roles', () => Request.get(`myteam/roles/`), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    retry: false,

    onError: (err) => {},
    select: (data) => data,
  });

export const useTenantData = (name, CB) =>
  useQuery(
    'Tenants',
    () => Axios.post(`tenants/config/`, { hostname: name }, { withCredentials: false }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      retry: false,

      select: (data) => data?.data,
      onSuccess: (data) => CB(data),
    }
  );
