import { useContext } from 'react';
import Axios from 'axios';
import { API, Auth } from 'aws-amplify';
import { UserContext } from 'index';
import { AUTH_TYPE } from 'Constants/others';
import { isEmpty } from 'lodash';
import { gethostName } from 'Helper/miscellaneous';
import Constants from 'utils/constants';

const axiosInstance = Axios.create({
  withCredentials: true,
  baseURL: Constants.PROD_URL,
  headers: {
    'x-host': gethostName(),
    'x-cookie': document.cookie,
  },
});

if (process.env?.NODE_ENV === 'development') {
  axiosInstance.defaults.headers['x-cookie'] = document.cookie;
}

const getAuthType = () =>
  !isEmpty(localStorage.getItem('tenant'))
    ? JSON.parse(localStorage.getItem('tenant'))?.auth_source
    : 'sl';

const awsCustomHeaders = async () => ({
  Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  'Content-type': 'application/json',
  'x-date': new Date().toString(),
  'x-tzoffset': new Date().getTimezoneOffset(),
  'x-host': gethostName(),
});

export const Request = {
  get: async (props) => {
    return getAuthType() === AUTH_TYPE.AWS
      ? API.get('MockCsAPI', props, { headers: await awsCustomHeaders() })
      : axiosInstance.get(props).then((data) => data?.data);
  },
  post: async (props, data) =>
    getAuthType() === AUTH_TYPE.AWS
      ? API.post('MockCsAPI', props, {
          body: data,
          headers: await awsCustomHeaders(),
        })
      : axiosInstance.post(props, data).then((data) => data?.data),

  put: async (props, data) =>
    getAuthType() === AUTH_TYPE.AWS
      ? API.put('MockCsAPI', props, {
          body: data,
          headers: await awsCustomHeaders(),
        })
      : axiosInstance.put(props, data).then((data) => data?.data),

  delete: async (props, data) =>
    getAuthType() === AUTH_TYPE.AWS
      ? API.del('MockCsAPI', props, {
          body: data,
          headers: await awsCustomHeaders(),
        })
      : axiosInstance({ url: props, method: 'DELETE', data: data ?? {} }).then(
          (data) => data?.data
        ),

  patch: async (props, data) =>
    getAuthType() === AUTH_TYPE.AWS
      ? API.patch('MockCsAPI', props, {
          body: data,
          headers: await awsCustomHeaders(),
        })
      : axiosInstance.patch(props, data).then((data) => data?.data),
};
