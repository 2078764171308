import * as React from 'react';

const SvgDown = (props) => (
  <svg viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 .763 9.272 0 5 4.474.728 0 0 .763 5 6l5-5.237Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgDown;

//#4A4A4A
