import { useState } from 'react';
import { Container, Card, Row, Col, Image, InputGroup } from 'react-bootstrap';
import hidePasswordIcon from 'assets/images/hidepassword.svg';
import showPasswordIcon from 'assets/images/showpassword.svg';
import footerLogo from 'assets/images/emtropyfulllogo.svg';
import loader from 'assets/images/loading.png';
import THEME from 'Constants/theme';
import { getTenant } from 'utils/functions';
import { isEmpty } from 'lodash';
import { AUTH_PAGE_TYPE } from 'Constants/auth';
import styled from 'styled-components';

const ForgotLink = styled.span`
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.primary || '#5fc6f2'};
  }
`;

const Login = ({ onLogin, loading, setAuthType, username: defaultUsername }) => {
  const [username, setUsername] = useState(() => defaultUsername ?? '');
  const [password, setPassword] = useState('');
  const [showPassword, setPasswordStatus] = useState(false);

  return (
    <div className="logincontainer" style={{ background: '#eff2f6' }}>
      <Container fluid={true}>
        <Row>
          <Col style={{ paddinRight: 0, flex: '0 0 30%', maxWidth: '30%' }} sm="4"></Col>
          <Col
            sm="4"
            style={{ paddinRight: 0, flex: '0 0 40%', maxWidth: '40%' }}
            className="logincol">
            <img className="logo" style={{ height: 30 }} src={getTenant().logo} />

            <div>
              <form className="loginform">
                <Card style={{ borderRadius: THEME.borderRadius }}>
                  <Card.Body>
                    {
                      <Row>
                        <Col md="1"></Col>
                        <Col md="10">
                          <h2 style={{ color: THEME.black, fontFamily: THEME.primaryFont }}>
                            Login
                          </h2>
                          <div className="input-password" style={{ marginTop: 40 }}>
                            <input
                              style={{ outline: 'none' }}
                              disabled={loading ? true : false}
                              id="username"
                              key="username"
                              name="username"
                              onChange={(e) => setUsername(e.target.value)}
                              value={username}
                              type="text"
                              placeholder="Enter your email"
                              required
                            />
                          </div>
                          <div className="input-password horizontal">
                            <input
                              style={{ outline: 'none' }}
                              disabled={loading ? true : false}
                              id="password"
                              key="password"
                              name="password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Enter your password"
                              required
                            />
                            <Image
                              onClick={() => setPasswordStatus((prev) => !prev)}
                              src={showPassword ? hidePasswordIcon : showPasswordIcon}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <button
                              style={{
                                background: THEME.primary,
                                borderColor: THEME.primary,
                                boxShadow: 'unset',
                                outline: 'none',
                                fontFamily: THEME.primaryFont,
                                borderRadius: THEME.borderRadius,
                              }}
                              data-testid={'login-button'}
                              disabled={
                                loading || isEmpty(username) || isEmpty(password) ? true : false
                              }
                              type="submit login-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                onLogin({
                                  username,
                                  password,
                                });
                              }}>
                              <span className="loading-login">
                                {loading ? <Image className="icon rotation" src={loader} /> : null}
                                <span className="text">
                                  {loading ? 'Logging in...' : 'Log in to your account'}
                                </span>
                              </span>
                            </button>
                          </div>
                          <div style={{ width: '100%', fontFamily: THEME.primaryFont }}>
                            <Row className="align-items-center">
                              <Col md={1}></Col>
                              <Col md={10} className="forgot-password">
                                <ForgotLink
                                  onClick={() => setAuthType(AUTH_PAGE_TYPE.FORGOT_PASSWORD)}
                                  data-testid={'forgot-password-button'}>
                                  Forgot Password?
                                </ForgotLink>
                              </Col>
                              <Col md={1}></Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md="1"></Col>
                      </Row>
                    }
                  </Card.Body>
                </Card>
              </form>
            </div>
          </Col>
          <Col sm="4" style={{ paddinRight: 0, flex: '0 0 30%', maxWidth: '30%' }}></Col>
        </Row>
      </Container>
      <span className="footer">
        {/* <Image src={footerLogo} /> */}
        <span className="text">&copy; {`${new Date().getFullYear()} SupportLogic`}</span>
      </span>
    </div>
  );
};

export default Login;
