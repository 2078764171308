import * as React from 'react';

const SvgNext = (props) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={20} height={20} rx={10} fill="#564FCA" />
    <path
      d="m8 14 4-4.286L8 6"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgNext;
