import { useState } from 'react';
import THEME from 'Constants/theme';
import { Image } from 'react-bootstrap';
import loader from 'assets/images/loading.png';
import { AUTH_PAGE_TYPE } from 'Constants/auth';

import hidePasswordIcon from 'assets/images/hidepassword.svg';
import showPasswordIcon from 'assets/images/showpassword.svg';

const SubmitView = ({ onSubmit, loading, setAuthType, onResend }) => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setPasswordStatus] = useState(false);

  const handleFocus = () => {
    const codeDOM = document.getElementById('code1');
    if (codeDOM.hasAttribute('readonly')) {
      codeDOM.removeAttribute('readonly');
    }
  };

  return (
    <div>
      <p className="helptext" style={{ marginTop: 20 }}>
        We have sent a code to your email. Please enter the code here to set a new password.
      </p>
      <div className="input-password" style={{ marginTop: 40 }}>
        <input
          style={{ outline: 'none' }}
          id="code1"
          name="code"
          onChange={(e) => setCode(e.target.value)}
          value={code}
          type="text"
          placeholder="Code"
          required
          autoComplete="off"
          readOnly
          onFocus={handleFocus}
          data-testid={'verification-code-field'}
        />
      </div>
      <div className="input-password horizontal">
        <input
          style={{ outline: 'none' }}
          id="password2"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeholder="New Password"
          required
          autoComplete="off"
          data-testid={'new-password-field'}
        />
        <Image
          onClick={() => setPasswordStatus((prev) => !prev)}
          src={showPassword ? hidePasswordIcon : showPasswordIcon}
        />
      </div>
      <div style={{ marginTop: 10, width: '100%' }}>
        <span
          style={{
            justifyContent: 'center',
            color: THEME.primary,
            fontFamily: THEME.primaryFont,
          }}
          className="password-policy">
          Use 8+ characters with a mix of uppercase, lower case letters, and numbers.
        </span>
      </div>
      <button
        style={{
          background: THEME.primary,
          borderColor: THEME.primary,
          boxShadow: 'unset',
          outline: 'none',
          fontFamily: THEME.primaryFont,
          borderRadius: THEME.borderRadius,
        }}
        disabled={loading ? true : false}
        type="submit"
        data-testid={'new-password-submit-button'}
        onClick={(e) => {
          e.preventDefault();
          onSubmit({ code, password });
        }}>
        <span className="loading-login">
          {loading ? <Image className="icon rotation" src={loader} /> : null}
          <span className="text">{loading ? 'Submitting...' : 'Submit'}</span>
        </span>
      </button>
      <span style={{ fontFamily: THEME.primaryFont }} className="helptext">
        Didn't receive the code?
      </span>{' '}
      &nbsp;
      <span
        style={{ color: THEME.primary, fontFamily: THEME.primaryFont }}
        className="blue-small-text-non-capital"
        onClick={onResend}>
        Resend code
      </span>
      <div style={{ width: '100%', marginTop: 20 }}>
        <span
          style={{
            justifyContent: 'center',
            color: THEME.primary,
            fontFamily: THEME.primaryFont,
          }}
          onClick={() => setAuthType(AUTH_PAGE_TYPE.LOGIN)}
          className="blue-small-text">
          Back to Log In
        </span>
      </div>
    </div>
  );
};

export default SubmitView;
