import * as React from 'react';

const SvgUsergroup = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.3446 20.6248V18.9304C17.3446 18.0316 16.9875 17.1696 16.352 16.5341C15.7164 15.8985 14.8545 15.5415 13.9557 15.5415H7.17794C6.27915 15.5415 5.41718 15.8985 4.78164 16.5341C4.1461 17.1696 3.78906 18.0316 3.78906 18.9304V20.6248"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5686 12.1527C12.4402 12.1527 13.9574 10.6355 13.9574 8.76387C13.9574 6.89225 12.4402 5.375 10.5686 5.375C8.69694 5.375 7.17969 6.89225 7.17969 8.76387C7.17969 10.6355 8.69694 12.1527 10.5686 12.1527Z"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4284 20.625V18.9306C22.4278 18.1797 22.1779 17.4503 21.7179 16.8569C21.2578 16.2634 20.6137 15.8396 19.8867 15.6519"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4961 5.48535C17.2251 5.67199 17.8712 6.09594 18.3326 6.69036C18.794 7.28478 19.0444 8.01585 19.0444 8.76832C19.0444 9.5208 18.794 10.2519 18.3326 10.8463C17.8712 11.4407 17.2251 11.8647 16.4961 12.0513"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgUsergroup;
