import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export default styled(Modal)`
  div.modal-content {
    padding: 16px 32px;
    border-radius: ${(props) => props.theme.borderRadius};
  }

  div.modal-dialog {
    min-width: 640px;
  }
`;

export { default as Header } from './header';
export { default as Footer } from './footer';
export { default as Body } from './body';
