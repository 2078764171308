import * as React from 'react';

const SvgScore = (props) => (
  <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.4375 2.625L2.56497 4.37747C8.53125 3.84375 9.34375 8.71875 8.125 11.1562L10.1562 13.1875H13.8125C16.4125 13.1875 17.0625 11.5625 17.0625 10.75V2.625C17.0625 1.325 15.7083 1 15.0312 1H4.46875C2.84375 1 2.4375 2.08333 2.4375 2.625Z"
      fill={props?.color}
      stroke={props?.color}
    />
    <g clip-path="url(#clip0_3451_1888)">
      <path
        d="M7.82064 12.8841L6.15007 11.2106M7.07585 8.97396C7.07585 9.81347 6.74235 10.6186 6.14873 11.2122C5.55511 11.8058 4.74999 12.1393 3.91048 12.1393C3.07098 12.1393 2.26585 11.8058 1.67223 11.2122C1.07861 10.6186 0.745117 9.81347 0.745117 8.97396C0.745117 8.13445 1.07861 7.32933 1.67223 6.73571C2.26585 6.14209 3.07098 5.80859 3.91048 5.80859C4.74999 5.80859 5.55511 6.14209 6.14873 6.73571C6.74235 7.32933 7.07585 8.13445 7.07585 8.97396Z"
        stroke={props?.color}
        stroke-linecap="round"
      />
    </g>
    <rect x="8.75" y="3.4375" width="4.0625" height="0.8125" rx="0.40625" fill="white" />
    <rect x="10.375" y="8.3125" width="3.25" height="0.8125" rx="0.40625" fill="white" />
    <rect x="10.375" y="5.875" width="4.875" height="0.8125" rx="0.40625" fill="white" />
    <defs>
      <clipPath id="clip0_3451_1888">
        <rect width="8.9375" height="8.9375" fill="white" transform="translate(0 5.0625)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgScore;
