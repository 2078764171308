import * as React from 'react';

const QCIcon = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.209 7.33203L9.44805 12.832L7.33398 10.7609"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.6703 4.59793C15.828 3.90871 14.2395 3.39824 10.7714 1.83203C7.30344 3.39824 5.71488 3.90871 1.87262 4.59793C1.17653 15.6306 10.1089 19.4402 10.7714 19.707C11.434 19.4402 20.3664 15.6306 19.6703 4.59793Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default QCIcon;
