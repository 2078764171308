import { useState } from 'react';
import { Container, Card, Row, Col, Image } from 'react-bootstrap';
import footerLogo from 'assets/images/emtropyfulllogo.svg';
import THEME from 'Constants/theme';
import { getTenant } from 'utils/functions';
import SendView from './sendView';
import SubmitView from './submitView';
import CompleteView from './completeView';
import { FORGOT_PASSWORD_PAGE_VIEW } from 'Constants/auth';

const renderPageView = ({
  pageView,
  onForgotPassword,
  onForgotPasswordSubmit,
  setPageView,
  username,
  ...props
}) => {
  switch (pageView) {
    case FORGOT_PASSWORD_PAGE_VIEW.SEND_VIEW:
      return (
        <SendView
          onSubmit={({ ...props }) =>
            onForgotPassword({ ...props }, (bol) => {
              if (bol) setPageView(FORGOT_PASSWORD_PAGE_VIEW.SUBMIT_VIEW);
            })
          }
          {...props}
        />
      );

    case FORGOT_PASSWORD_PAGE_VIEW.SUBMIT_VIEW:
      return (
        <SubmitView
          onSubmit={({ ...props }) =>
            onForgotPasswordSubmit({ ...props, username }, (bol) => {
              if (bol) setPageView(FORGOT_PASSWORD_PAGE_VIEW.COMPLETE_VIEW);
            })
          }
          onResend={() => onForgotPassword({ username }, () => {})}
          {...props}
        />
      );

    case FORGOT_PASSWORD_PAGE_VIEW.COMPLETE_VIEW:
      return <CompleteView {...props} />;

    default:
      return <SendView {...props} />;
  }
};

const ForgotPassword = ({
  setAuthType,
  onForgotPassword,
  onForgotPasswordSubmit,
  loading,
  username,
}) => {
  const [pageView, setPageView] = useState(FORGOT_PASSWORD_PAGE_VIEW.SEND_VIEW);

  return (
    <div className="logincontainer" style={{ background: '#eff2f6' }}>
      <Container className="remove-fluid" fluid={true}>
        <Row>
          <Col style={{ paddinRight: 0, flex: '0 0 30%', maxWidth: '30%' }} sm="4"></Col>
          <Col
            sm="4"
            style={{ paddinRight: 0, flex: '0 0 40%', maxWidth: '40%' }}
            className="logincol">
            <img className="logo" style={{ height: 30 }} src={getTenant().logo} />

            <div>
              <form className="loginform">
                <Card style={{ borderRadius: THEME.borderRadius }}>
                  <Card.Body>
                    {
                      <Row>
                        <Col md="12" style={{ paddingRight: 50, paddingLeft: 50 }}>
                          {renderPageView({
                            pageView,
                            loading,
                            onForgotPassword,
                            onForgotPasswordSubmit,
                            setAuthType,
                            setPageView,
                            username,
                          })}
                        </Col>
                      </Row>
                    }
                  </Card.Body>
                </Card>
              </form>
            </div>
          </Col>
          <Col sm="4" style={{ paddinRight: 0, flex: '0 0 30%', maxWidth: '30%' }}></Col>
        </Row>
      </Container>
      <span className="footer">
        {/* <Image src={footerLogo} /> */}
        <span className="text">&copy; {`${new Date().getFullYear()} SupportLogic`}</span>
      </span>
    </div>
  );
};

export default ForgotPassword;
