import React, { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from './Constants/theme';
import App from './App';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Spin, ConfigProvider } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Axios from 'axios';
import Constants from './utils/constants';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'Styles/index.less';
import './Components/component.scss';

import './index.css';
import { gethostName } from 'Helper/miscellaneous';

export const UserContext = createContext({});

const queryClient = new QueryClient();

Spin.setDefaultIndicator(<SyncOutlined spin />);

Axios.defaults.baseURL = Constants.PROD_URL;
// Axios.defaults.withCredentials = true;
Axios.defaults.headers['x-host'] = gethostName();

if (process.env?.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://802eeb671ede459d99d76867222aca82@elevatesx-sentry.supportlogic.io/3',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

const GlobalStyles = createGlobalStyle`
  #freshworks-container iframe#launcher-frame {
    z-index: 5!important;
    bottom: 65px!important;
    right: 5px!important;
    width: 57px!important; 
    height: 42px!important; 
    min-width: 57px!important;
  }
  #freshworks-container div#freshworks-frame-wrapper {
    bottom: 102px!important;
    right: 12px!important;
  }
`;

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,

    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
  root.render(
    <LDProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0FBCF9',
            fontFamily: 'Avenir, Helvetica Neue, Helvetica, Arial, sans-serif',
            borderRadiusLG: '4px',
            borderRadiusSM: '4px',
            colorLink: '#0FBCF9',
          },

          components: {
            Button: {
              borderRadius: '4px',
            },
          },
        }}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ToastContainer
            position="top-right"
            hideProgressBar={true}
            autoClose={3000}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
          />
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
            <ReactQueryDevtools
              initialIsOpen={false}
              position="bottom-right"
              toggleButtonProps={{ style: { bottom: '100px', right: '32px' } }}
            />
          </QueryClientProvider>
        </ThemeProvider>
      </ConfigProvider>
    </LDProvider>
  );
})();
