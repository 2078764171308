import * as React from 'react';

const SvgNote = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 19H6.99999C5.89542 19 4.99999 18.1045 5 16.9999L5.00008 4.99999C5.00008 3.89542 5.89551 3 7.00008 3H16.0003C17.1049 3 18.0003 3.89543 18.0003 5V10.5M14.5003 18.5H19M19 18.5L17 16M19 18.5L17 20.5M8.50031 7H14.5003M8.50031 10H14.5003M8.50031 13H11.5003"
      stroke={props?.color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgNote;
