import { lazy, Suspense, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Error404 } from 'Pages/Errors';
import { Container } from 'react-bootstrap';
import { Spinner as NewSpinner } from 'UI/Loading';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LAUNCHDARKLY_FLAGS } from 'Constants/launchDarklyFlag';
import { UserContext } from 'index';

const AgentTickets = lazy(() => import('Pages/dashboardScreen/agentScreen'));
const TeamView = lazy(() => import('Pages/dashboardScreen/teamScreen'));
const Dashboard = lazy(() => import('Pages/Dashboard'));

const ProfileView = lazy(() => import('Pages/ProfileComponent'));

const ManageUsers = lazy(() => import('Pages/settings/management/users/users'));
const ManageGroups = lazy(() => import('Pages/settings/management/groups/groups'));
// const ManageGroups = lazy(() => import('Pages/settings/ManageGroups'));
const ManageRoles = lazy(() => import('Pages/settings/ManageRoles'));

const TriageScreen = lazy(() => import('Pages/triageScreen2'));

const SearchScreen = lazy(() => import('Pages/searchScreen'));
const TicketScreen = lazy(() => import('Pages/ticketScreen'));
const TicketScreen2 = lazy(() => import('Pages/ticketScreen2'));
const CoachingScreen = lazy(() => import('Pages/coachingScreen'));

const ScoreScreen = lazy(() => import('Pages/settings/scorecard_new'));
const CreateScorecard = lazy(() => import('Pages/settings/createScore'));
const AutoQAOnboard = lazy(() => import('Pages/settings/autoQAOnboard'));
const EditScorecard = lazy(() => import('Pages/settings/editScore'));
const ReportScreen = lazy(() => import('Pages/settings/reports'));

const QualityConsole = lazy(() => import('Pages/qaScreen/innerpages/qualityConsole'));
const AssignmentScreen = lazy(() => import('Pages/qaScreen/innerpages/assignment'));
const DisputeScreen = lazy(() => import('Pages/qaScreen/innerpages/disputes'));
const CreateAssignmentScreen = lazy(() => import('Pages/qaScreen/innerpages/createAssignment2'));
const CreateCalibrateScreen = lazy(() => import('Pages/qaScreen/innerpages/createCalibrate2'));
const CreateGradeScreen = lazy(() => import('Pages/qaScreen/innerpages/createGrade'));
const CreateGradeScreen2 = lazy(() => import('Pages/qaScreen/innerpages/createGrade2'));

let currentTicketScreen = (props, showOLDUI) => {
  return props?.flags?.[LAUNCHDARKLY_FLAGS.NEW_SUPPORTHUB_UI] && !showOLDUI
    ? TicketScreen2
    : TicketScreen;
};

const LoadingComponent = () => (
  <Container fluid={true}>
    <NewSpinner className="center-loader" animation="border" />
  </Container>
);

const Routes = (props) => {
  const userContext = useContext(UserContext);

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Switch>
        {/* Independent Ticket Detail Page Route */}
        <Route
          exact
          path="/home/ticket/:id"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />

        <Route
          exact
          path="/home/search/ticket/:id"
          name="Label"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />
        <Route exact path="/home/search" name="TicketReview" component={SearchScreen} />

        <Route
          exact
          path="/home/dashboard/group/:group_id/team/:team_id/agent/:agent_id/ticket/:id"
          name="Label"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />
        <Route
          exact
          path="/home/dashboard/group/:group_id/team/:team_id/ticket/:id"
          name="Label"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />
        <Route
          exact
          path="/home/dashboard/agent/:agent_id/ticket/:id"
          name="Label"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />
        <Route
          exact
          path="/home/dashboard/ticket/:id"
          name="Label"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />
        <Route
          exact
          path="/home/dashboard/group/:group_id/team/:team_id/agent/:id"
          name="AgentTickets"
          component={AgentTickets}
        />
        <Route
          exact
          path="/home/dashboard/team/:team_id/agent/:id"
          name="AgentTickets"
          component={AgentTickets}
        />
        <Route
          exact
          path="/home/dashboard/agent/:id"
          name="AgentTickets"
          component={AgentTickets}
        />
        <Route exact path="/home/dashboard/team/:id" name="TeamView" component={TeamView} />

        {/* <Route
          exact
          path="/new/integrations"
          name="IntegrationList"
          component={IntegrationListPage}
        />
        <Route
          exact
          path="/new/integrations/slack/auth"
          name="IntegrationList"
          component={IntegrationSlackAuth}
        /> */}

        <Route exact path="/profile" name="Profile" component={ProfileView} />

        <Route exact path="/settings/profile" name="Profile" component={ProfileView} />
        <Route exact path="/settings/manage-users" name="Profile" component={ManageUsers} />
        <Route exact path="/settings/manage-groups" name="Profile" component={ManageGroups} />
        <Route exact path="/settings/manage-roles" name="Profile" component={ManageRoles} />

        <Route exact path="/home/qa/scorecard" name="Scorecard" component={ScoreScreen} />

        <Route
          exact
          path="/home/qa/scorecard/create"
          name="Create Scorecard"
          component={CreateScorecard}
        />

        <Route
          exact
          path="/home/qa/scorecard/edit/:id"
          name="Edit Scorecard"
          component={EditScorecard}
        />

        <Route exact path="/settings/reports" name="Reports" component={ReportScreen} />

        {/** Triage Routes */}
        <Route exact path="/home/triage" name="Triage" component={TriageScreen} />

        <Route
          exact
          path="/home/triage/ticket/:id"
          name="Triage"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />

        <Route exact path="/home/coaching" name="Coaching" component={CoachingScreen} />

        <Route exact path="/home/qa/console" name="Quality Console" component={QualityConsole} />

        <Route exact path="/home/qa/assignment" name="QA Assignment" component={AssignmentScreen} />

        <Route exact path="/home/qa/dispute" name="Dispute" component={DisputeScreen} />

        <Route
          exact
          path="/home/qa/assignment/ticket/:id"
          name="assignment ticket"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />

        <Route
          exact
          path="/home/qa/dispute/ticket/:id"
          name="dispute ticket"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />

        <Route
          exact
          path="/home/coaching/ticket/:id"
          name="coaching ticket"
          component={currentTicketScreen(props, userContext?.showOLDUI ?? false)}
        />

        <Route
          exact
          path="/home/qa/assignment/create"
          name="Assignment"
          component={CreateAssignmentScreen}
        />

        <Route
          exact
          path="/home/qa/assignment/calibrate"
          name="Calibrate"
          component={CreateCalibrateScreen}
        />

        <Route exact path="/home/dashboard" name="Dashboard" component={Dashboard} />

        <Route exact path="/home/qa/assignment/grade2" name="Grade" component={CreateGradeScreen} />
        <Route exact path="/home/qa/assignment/grade" name="Grade" component={CreateGradeScreen2} />

        <Redirect exact to={'/home/dashboard'} from={'/'} />
        <Route
          exact
          path="/home/qa/scorecard/autoqaonboard"
          name="AutoQAOnboard"
          component={AutoQAOnboard}
        />

        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default withLDConsumer()(Routes);
