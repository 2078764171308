import * as React from 'react';

const SvgOutbox = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="path-1-inside-1_9835_9218" fill={props?.color}>
      <path d="M3 13C3 12.4477 3.44772 12 4 12H20C20.5523 12 21 12.4477 21 13V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V13Z" />
    </mask>
    <path
      d="M3 12H21H3ZM22.4 20C22.4 21.3255 21.3255 22.4 20 22.4H4C2.67452 22.4 1.6 21.3255 1.6 20L4.4 19.6H19.6L22.4 20ZM4 22.4C2.67452 22.4 1.6 21.3255 1.6 20V14.4C1.6 13.0745 2.67452 12 4 12H4.4C4.4 12 4.4 12.4477 4.4 13V19.6L4 22.4ZM20 12C21.3255 12 22.4 13.0745 22.4 14.4V20C22.4 21.3255 21.3255 22.4 20 22.4L19.6 19.6V13C19.6 12.4477 19.6 12 19.6 12H20Z"
      fill={props?.color}
      mask="url(#path-1-inside-1_9835_9218)"
    />
    <path
      d="M12.495 2.50503C12.2216 2.23166 11.7784 2.23166 11.505 2.50503L7.05025 6.9598C6.77689 7.23316 6.77689 7.67638 7.05025 7.94975C7.32362 8.22311 7.76684 8.22311 8.0402 7.94975L12 3.98995L15.9598 7.94975C16.2332 8.22311 16.6764 8.22311 16.9497 7.94975C17.2231 7.67638 17.2231 7.23316 16.9497 6.9598L12.495 2.50503ZM12.7 16L12.7 3L11.3 3L11.3 16L12.7 16Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgOutbox;
