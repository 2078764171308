import * as React from 'react';

const SvgTicket = (props) => (
  <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.994 3.789a.533.533 0 0 0 .534-.533V1.122a.534.534 0 0 0-.534-.533h-16a.533.533 0 0 0-.533.533v11.734a.533.533 0 0 0 .533.533h16a.533.533 0 0 0 .534-.533v-2.134a.533.533 0 0 0-.534-.533.533.533 0 0 1 0-1.067.533.533 0 0 0 .534-.533v-3.2a.534.534 0 0 0-.534-.533.533.533 0 0 1 0-1.067Zm-1.6.533a1.597 1.597 0 0 0 1.067 1.502v2.33a1.59 1.59 0 0 0 0 3.003v1.165h-2.133v-1.6a.533.533 0 0 0-1.067 0v1.6H1.528V1.656H13.26v1.6a.533.533 0 0 0 1.067 0v-1.6h2.133V2.82a1.596 1.596 0 0 0-1.067 1.501Z"
      fill="#000"
    />
    <path
      d="M13.795 4.855a.533.533 0 0 0-.533.534v3.2a.533.533 0 0 0 1.066 0v-3.2a.533.533 0 0 0-.533-.534ZM10.062 3.256H4.729a.533.533 0 0 0 0 1.067h5.333a.533.533 0 1 0 0-1.067ZM10.062 5.389H4.729a.533.533 0 0 0 0 1.066h5.333a.533.533 0 1 0 0-1.066ZM10.062 7.522H4.729a.533.533 0 0 0 0 1.067h5.333a.533.533 0 1 0 0-1.067ZM10.062 9.655H4.729a.533.533 0 0 0 0 1.067h5.333a.533.533 0 1 0 0-1.067Z"
      fill="#000"
    />
  </svg>
);

export default SvgTicket;
