export const SIZE = {
  LARGE: 'large',
  SMALL: 'small',
  DEFAULt: 'default',
};

export const SCORECARD_TYPE = { NEW: 'New', OLD: 'Archived' };

export const AUTH_TYPE = { SL: 'sl', AWS: 'aws' };

export const AUDIT_TYPE = { AUTO: 'auto', MANUAL: 'manual' };

export const SCORING_TYPE = { COMMENT: 'Comment', TICKET: 'Ticket', AGENT: 'Agent' };

export const SORK_ORDER_KEY = {
  ascend: 'asc',
  descend: 'desc',
};
