import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem;
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  /* align-items: center;
  justify-content: center; */
`;

const PageWrapper = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

export default PageWrapper;
