import * as React from 'react';

const SvgExtlink = (props) => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5 10.667v4.166a1.666 1.666 0 0 1-1.667 1.667H3.167A1.667 1.667 0 0 1 1.5 14.833V3.167A1.667 1.667 0 0 1 3.167 1.5h4.166m2.917 6.25L16.5 1.5l-6.25 6.25Zm2.083-6.25H16.5v4.167L12.333 1.5Z"
      stroke={props?.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExtlink;
