import * as React from 'react';

const SvgIdcard = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.875 5H3.375C3.01033 5 2.66059 5.14487 2.40273 5.40273C2.14487 5.66059 2 6.01033 2 6.375V17.375C2 17.7397 2.14487 18.0894 2.40273 18.3473C2.66059 18.6051 3.01033 18.75 3.375 18.75H19.875C20.2397 18.75 20.5894 18.6051 20.8473 18.3473C21.1051 18.0894 21.25 17.7397 21.25 17.375V6.375C21.25 6.01033 21.1051 5.66059 20.8473 5.40273C20.5894 5.14487 20.2397 5 19.875 5ZM19.875 17.375H3.375V6.375H19.875V17.375Z"
      fill={props?.color}
    />
    <path
      d="M13 10.5H17.8125C17.9948 10.5 18.1697 10.4276 18.2986 10.2986C18.4276 10.1697 18.5 9.99484 18.5 9.8125C18.5 9.63016 18.4276 9.4553 18.2986 9.32636C18.1697 9.19743 17.9948 9.125 17.8125 9.125H13C12.8177 9.125 12.6428 9.19743 12.5139 9.32636C12.3849 9.4553 12.3125 9.63016 12.3125 9.8125C12.3125 9.99484 12.3849 10.1697 12.5139 10.2986C12.6428 10.4276 12.8177 10.5 13 10.5Z"
      fill={props?.color}
    />
    <path
      d="M13 13.25H15.75C15.9323 13.25 16.1072 13.1776 16.2361 13.0486C16.3651 12.9197 16.4375 12.7448 16.4375 12.5625C16.4375 12.3802 16.3651 12.2053 16.2361 12.0764C16.1072 11.9474 15.9323 11.875 15.75 11.875H13C12.8177 11.875 12.6428 11.9474 12.5139 12.0764C12.3849 12.2053 12.3125 12.3802 12.3125 12.5625C12.3125 12.7448 12.3849 12.9197 12.5139 13.0486C12.6428 13.1776 12.8177 13.25 13 13.25Z"
      fill={props?.color}
    />
    <path
      d="M5.4375 15.3125C5.61984 15.3125 5.7947 15.2401 5.92364 15.1111C6.05257 14.9822 6.125 14.8073 6.125 14.625C6.125 14.4325 6.73 14.0131 7.005 13.9375H7.08063C7.36454 13.8765 7.62619 13.7386 7.83687 13.5388C8.063 13.5915 8.29825 13.5915 8.52437 13.5388C8.73506 13.7386 8.99671 13.8765 9.28063 13.9375H9.32875C9.62437 14.0475 10.2087 14.4669 10.2362 14.6594C10.2362 14.8417 10.3087 15.0166 10.4376 15.1455C10.5665 15.2744 10.7414 15.3469 10.9237 15.3469C11.1061 15.3469 11.281 15.2744 11.4099 15.1455C11.5388 15.0166 11.6112 14.8417 11.6112 14.6594C11.6112 13.4425 9.98188 12.6931 9.74125 12.5969C10.1315 11.9706 10.3099 11.2354 10.25 10.5C10.2234 9.9616 9.99752 9.45233 9.61634 9.07116C9.23517 8.68998 8.7259 8.46414 8.1875 8.4375C7.6491 8.46414 7.13983 8.68998 6.75866 9.07116C6.37748 9.45233 6.15164 9.9616 6.125 10.5C6.07257 11.224 6.25066 11.946 6.63375 12.5625C6.37937 12.6588 4.75 13.4081 4.75 14.625C4.75 14.8073 4.82243 14.9822 4.95136 15.1111C5.0803 15.2401 5.25516 15.3125 5.4375 15.3125ZM8.1875 9.8125C8.36142 9.83638 8.52275 9.91649 8.64688 10.0406C8.77101 10.1648 8.85112 10.3261 8.875 10.5C8.92092 10.7823 8.90073 11.0714 8.81604 11.3446C8.73134 11.6178 8.58444 11.8676 8.38687 12.0744C8.3607 12.1006 8.32962 12.1213 8.29541 12.1355C8.2612 12.1497 8.22453 12.157 8.1875 12.157C8.15047 12.157 8.1138 12.1497 8.07959 12.1355C8.04538 12.1213 8.0143 12.1006 7.98813 12.0744C7.79012 11.8679 7.64292 11.6181 7.55819 11.3448C7.47347 11.0715 7.45354 10.7823 7.5 10.5C7.52388 10.3261 7.60399 10.1648 7.72812 10.0406C7.85225 9.91649 8.01358 9.83638 8.1875 9.8125Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgIdcard;
