import * as React from 'react';

const SvgUp = (props) => (
  <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.487 5.094a.667.667 0 0 0 .94.086l2.906-2.42v7.907a.667.667 0 1 0 1.334 0V2.76l2.906 2.42a.668.668 0 0 0 .854-1.026l-4-3.334-.1-.06L5.24.714a.667.667 0 0 0-.48 0L4.673.76l-.1.06-4 3.334a.667.667 0 0 0-.086.94Z"
      fill="#707683"
    />
  </svg>
);

export default SvgUp;
