import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Extlink } from 'UI/Icons';
import { useHistory } from 'react-router-dom';
import { Search as SearchIcon } from 'UI/Icons';
import THEME from 'Constants/theme';

const Wrapper = styled.div``;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: hsla(0, 0%, 86.7%, 0.4);
  z-index: 3;
  opacity: ${(props) => (props.active ? '1' : '0')};
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
`;

const Container = styled.div`
  position: relative;

  > div {
    &:first-child {
      cursor: ${(props) => (props.active ? 'default' : 'pointer')};
      position: relative;
      z-index: 6;
      transition: 0.3s ease;
      padding-top: 5px;
      transform: ${(props) => (props.active ? 'translate(-245px)' : 'none')};
      /* 
      > svg {
        display: block;
        width: 22px;
        height: 22px;
        color: ${(props) => (props.active ? props.theme.primary : '#9b9b9b')};
      } */
    }
  }

  > input {
    z-index: 5;
    position: absolute;
    top: 50%;
    right: -5px;
    margin-top: -12px;
    opacity: ${(props) => (props.active ? '1' : '0')};
    width: ${(props) => (props.active ? '240px' : '0px')};
    height: 25px;
    transition: 0.3s ease;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.primary};
    transform-origin: 100% 50%;
    font-size: 12px;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      font-family: ${(props) => props.theme.primaryFont};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #9d9d9d;
    }
  }
`;

const SearchResultContainer = styled.div`
  position: absolute;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 25%);

  z-index: 4;
  top: 30px;
  right: -15px;
  width: 295px;
  border-radius: 0 0 4px 4px;
  padding: 0 0 9px;

  &::before {
    content: '';
    height: 40px;
    width: 295px;
    right: 0;
    bottom: 100%;
    clip: rect(-5000px, 5000px, 40px, -5000px);
    position: absolute;
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 0 0 / 25%);
  }
`;

const AdvanceSearchWrapper = styled.div`
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  color: #9b9b9b;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 5px;
  z-index: 6;
  opacity: 0;
  transition: 0.3s ease;
  pointer-events: none;
  cursor: pointer;
  > svg {
    display: block;
    fill: #9b9b9b;
    width: 10px;
    height: 10px;
  }
`;

const Link = styled.a`
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => props.theme.primary};

  > svg {
    margin-left: 10px;
    width: 16px;
    height: 16px;

    > path {
      stroke: ${(props) => props.theme.primary};
    }
  }
`;

const SearchResult = styled.div`
  min-height: 40px;
  text-align: center;
  font-size: 12px;
  color: #9b9b9b;
`;

const ListSearchResult = styled.ul`
  margin: 0;
  padding: 0;

  > li {
    list-style-type: none;
  }
`;

const Search = () => {
  const [active, setActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if (!active) setSearchTerm('');
  }, [active]);

  const history = useHistory();

  const onNavSearch = () => {
    history.push('/home/search');
    setActive(false);
  };

  return (
    <Wrapper>
      <div>
        <Backdrop active={active} onClick={() => setActive(false)}></Backdrop>
        <Container active={active}>
          <div onClick={() => setActive((prev) => !prev)}>
            <SearchIcon height={26} color={'#9b9b9b'} />
          </div>

          <input
            value={searchTerm}
            type="number"
            placeholder="Search by Ticket ID"
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                setSearchTerm(e.target.value);
                history.push({ pathname: '/home/search', state: { term: searchTerm } });
                setActive(false);
              }
            }}
          />

          <CloseIconContainer>
            <svg
              data-icon="cross"
              className="_3wiPjcJ1lO-3Lf-oDfOKwP"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M5 5.7l3.6 3.6.7-.7L5.7 5l3.6-3.6-.7-.7L5 4.3 1.4.7l-.7.7L4.3 5 .7 8.6l.7.7L5 5.7z"></path>
            </svg>
          </CloseIconContainer>

          {active && (
            <SearchResultContainer>
              <AdvanceSearchWrapper>
                <Link onClick={onNavSearch}>
                  <span style={{ color: THEME.primary }}>Advanced Search</span>
                  <Extlink />
                </Link>
              </AdvanceSearchWrapper>
            </SearchResultContainer>
          )}
        </Container>
      </div>
    </Wrapper>
  );
};

export default Search;
