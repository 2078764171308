import { AUTH_PAGE_TYPE } from 'Constants/auth';

const CompleteView = ({ setAuthType }) => {
  return (
    <div>
      <p className="helptext">You password is reset. You can login now.</p>
      <br />
      <div style={{ width: '100%', marginTop: 20 }}>
        <span
          style={{ justifyContent: 'center' }}
          onClick={() => setAuthType(AUTH_PAGE_TYPE.LOGIN)}
          className="blue-small-text">
          Back to Log In
        </span>
      </div>
    </div>
  );
};

export default CompleteView;
