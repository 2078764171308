export const LAUNCHDARKLY_FLAGS = {
  SCORING_BREAKUP_TD: 'scoring-break-up-in-td',
  AUTOQA_DISCOVERY_WORKFLOW: 'auto-qa-discovery-workflow',
  AUTO_AND_MANUAL_QA_TD: 'auto-qa-and-manual-qa-in-td',
  SCHEDULE_ASSIGNMENT: 'schedule-assignment',
  AUTOQA_DISCOVERY_WORKFLOW_PART2: 'auto-qa-discovery-workflow-part-2',
  MARK_FOR_REVIEW: 'mark-for-review',
  AUTO_QA_ONBOARDING: 'auto-qa-onboarding',
  NEW_SUPPORTHUB_UI: 'new-supporthub-ui',
};
