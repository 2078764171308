import * as React from 'react';

const SvgInbox = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="path-1-inside-1_9835_9211" fill={props?.color}>
      <path d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V12Z" />
    </mask>
    <path
      d="M3 11H21H3ZM22.4 20C22.4 21.3255 21.3255 22.4 20 22.4H4C2.67452 22.4 1.6 21.3255 1.6 20L4.4 19.6H19.6L22.4 20ZM4 22.4C2.67452 22.4 1.6 21.3255 1.6 20V13.4C1.6 12.0745 2.67452 11 4 11H4.4C4.4 11 4.4 11.4477 4.4 12V19.6L4 22.4ZM20 11C21.3255 11 22.4 12.0745 22.4 13.4V20C22.4 21.3255 21.3255 22.4 20 22.4L19.6 19.6V12C19.6 11.4477 19.6 11 19.6 11H20Z"
      fill={props?.color}
      mask="url(#path-1-inside-1_9835_9211)"
    />
    <path
      d="M11.505 16.495C11.7784 16.7683 12.2216 16.7683 12.495 16.495L16.9497 12.0402C17.2231 11.7668 17.2231 11.3236 16.9497 11.0503C16.6764 10.7769 16.2332 10.7769 15.9598 11.0503L12 15.0101L8.0402 11.0503C7.76683 10.7769 7.32362 10.7769 7.05025 11.0503C6.77689 11.3236 6.77689 11.7668 7.05025 12.0402L11.505 16.495ZM11.3 3L11.3 16L12.7 16L12.7 3L11.3 3Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgInbox;
