import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Popover, { PopoverContent } from 'UI/Popover';
import THEME from 'Constants/theme';

import { Next } from 'UI/Icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.black};
`;

const Button = styled.a`
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  display: flex;

  > svg {
    height: 20px;
    > rect {
      fill: ${(props) => (props.inverse ? '#fff' : props.theme.primary)};
    }

    > path {
      stroke: ${(props) => (props.inverse ? props.theme.primary : '#fff')};
    }
  }
`;

const NextPrev = ({
  totalCount,
  onNext,
  onPrev,
  current = 1,
  showText = true,
  isNextDisabled = false,
  showNext = false,
  reviewed = false,
  inverse = false,
  testid = '',
}) => {
  const [currentCount, setCurrentCount] = useState();

  useEffect(() => {
    setCurrentCount(current);
  }, [current]);

  if (current <= 0) return null;

  if (!totalCount) return null;

  let prevStatus = currentCount === 1;

  return (
    <Wrapper>
      <Popover
        placement="bottom"
        content={
          <PopoverContent>
            <p>Previous</p>
          </PopoverContent>
        }>
        <Button
          inverse={inverse}
          data-testid={`${testid}-prev-button`}
          disabled={currentCount === 1}
          onClick={(e) => {
            e.stopPropagation();
            if (!prevStatus) {
              setCurrentCount(currentCount - 1);
              onPrev(currentCount - 1);
            }
          }}>
          <Next style={{ transform: 'rotate(-180deg)' }} />
        </Button>
      </Popover>
      {showText ? (
        <span style={{ margin: '0 4px', color: inverse ? '#fff' : THEME.black }}>
          {`${currentCount}/${totalCount}`}
        </span>
      ) : (
        <span style={{ minWidth: 10 }}></span>
      )}

      <Popover
        placement="bottom"
        content={
          <PopoverContent>
            <p>Next</p>
          </PopoverContent>
        }>
        <Button
          inverse={inverse}
          data-testid={`${testid}-next-button`}
          disabled={totalCount === currentCount || isNextDisabled}
          onClick={(e) => {
            e.stopPropagation();

            if (!(totalCount === currentCount || isNextDisabled)) {
              setCurrentCount(currentCount + 1);
              onNext(currentCount + 1);
            }
          }}>
          <Next />
        </Button>
      </Popover>
    </Wrapper>
  );
};

export default NextPrev;
