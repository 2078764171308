import * as React from 'react';

const SvgUser2 = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.5 15.125C15.3995 15.125 17.75 12.7745 17.75 9.875C17.75 6.9755 15.3995 4.625 12.5 4.625C9.6005 4.625 7.25 6.9755 7.25 9.875C7.25 12.7745 9.6005 15.125 12.5 15.125Z"
      stroke={props?.color}
      strokeWidth="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M4.54297 19.7185C5.34928 18.3216 6.50912 17.1616 7.90588 16.3551C9.30265 15.5486 10.8871 15.124 12.5 15.124C14.1129 15.124 15.6974 15.5486 17.0941 16.3551C18.4909 17.1616 19.6507 18.3216 20.457 19.7185"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgUser2;
