import { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { Spin, notification } from 'antd';
import styled from 'styled-components';
import { UserContext } from 'index';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Login from 'Pages/authScreen/login';
import ForgotPassword from 'Pages/authScreen/forgotPassword';
import RequireNewPassword from 'Pages/authScreen/requireNewPassword';
import { AUTH_PAGE_TYPE } from 'Constants/auth';

import { Auth } from 'aws-amplify';
import { handleError } from 'Helper/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const renderAuthPage = ({ type, ...props }) => {
  switch (type) {
    case AUTH_PAGE_TYPE.LOGIN:
      return <Login {...props} />;

    case AUTH_PAGE_TYPE.FORGOT_PASSWORD:
      return <ForgotPassword {...props} />;

    case AUTH_PAGE_TYPE.REQUIRE_NEW_PASSWORD:
      return <RequireNewPassword {...props} />;

    default:
      return <Login {...props} />;
  }
};

export default (ComposedComponent) => {
  const WithAwsAuth = (props) => {
    const [isAuthenticated, setAuthStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [userObject, setUserObject] = useState({});
    const [authPageType, setAuthType] = useState(AUTH_PAGE_TYPE.LOGIN);
    const { route } = useAuthenticator((context) => [context.user, context.route]);

    const onLogin = ({ username, password }) => {
      setLoading(true);
      localStorage.removeItem('username');
      localStorage.removeItem('homepage');
      Auth.signIn(username, password)
        .then((data) => {
          setLoading(false);
          if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setUserObject(data);
            setAuthType(AUTH_PAGE_TYPE.REQUIRE_NEW_PASSWORD);
          } else setAuthStatus(true);
        })
        .catch((err) => {
          setLoading(false);
          handleError(err?.message);
        });
    };

    const onForgotPassword = ({ username }, CB) => {
      setLoading(true);
      Auth.forgotPassword(username)
        .then((data) => {
          setUsername(username);
          notification.success({
            message: `Verification code sent to your ${data.CodeDeliveryDetails.DeliveryMedium}`,
          });
          setLoading(false);
          CB(true);
        })
        .catch((err) => {
          setLoading(false);
          handleError(err?.message);
          CB(false);
        });
    };

    const onForgotPasswordSubmit = ({ username, code, password }, CB) => {
      setLoading(true);
      Auth.forgotPasswordSubmit(username, code, password)
        .then((data) => {
          setLoading(false);
          CB(true);
        })
        .catch((err) => {
          setLoading(false);
          handleError(err?.message);
          CB(false);
        });
    };

    const onCompleteNewPassword = ({ password }) => {
      setLoading(true);
      Auth.completeNewPassword(userObject, password)
        .then((data) => {
          setLoading(false);
          setAuthStatus(true);
        })
        .catch((err) => {
          setLoading(false);
          handleError(err?.message);
        });
    };

    if (route === 'idle')
      return (
        <Wrapper>
          <Spin size="large" spinning={true} />
        </Wrapper>
      );
    if (route === 'authenticated' || isAuthenticated) return <ComposedComponent {...props} />;
    else
      return renderAuthPage({
        type: authPageType,
        onLogin,
        loading,
        setAuthType,
        onForgotPassword,
        onForgotPasswordSubmit,
        onCompleteNewPassword,
        username,
      });
  };

  return WithAwsAuth;
};
