import { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import styled from 'styled-components';
import { isEmpty, keys } from 'lodash';
import { Spin } from 'antd';

import withAuth from 'HOC/withAuth';
import withAwsAuth from 'HOC/withAwsAuth';
import routes from './Routes';
import RedirectScreen from 'Pages/redirectScreen';
import { useTenantData } from 'API/auth';
import Constants from 'utils/constants';

import { awsConfig } from './aws-exports';
import { AUTH_TYPE, AUDIT_TYPE } from 'Constants/others';
import { UserContext } from 'index';
import { gethostName } from 'Helper/miscellaneous';

import '@aws-amplify/ui-react/styles.css';
import SettingsContextProvider from 'Contexts/SettingsContext';

var mixpanel = require('mixpanel-browser');
mixpanel.init('b333044be28f599ed130f5b6cc2da4f4', { api_host: 'https://mp.emtropy.com' });

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getAuditType = (type, final = []) => {
  if (type === 'auto_manual') final = [AUDIT_TYPE.AUTO, AUDIT_TYPE.MANUAL];
  else final = [type];
  return final;
};

const App = () => {
  const [contextData, setContextData] = useState({});

  const {
    data: tenantData,
    isLoading: isTenantLoading,
    isError: isTenantError,
  } = useTenantData(gethostName(), async (data) => {
    localStorage.setItem('tenant', JSON.stringify(data ?? {}));
    localStorage.setItem('VERSION_CODE', Constants.VERSION_CODE);
    if (data?.auth_source === AUTH_TYPE.AWS) Amplify.configure(awsConfig({ tenant: data }));
  });

  useEffect(() => {
    if (isEmpty(tenantData)) return;

    let KPIS = [];

    if (tenantData?.config?.kpis?.calc_ces) KPIS.push('ces');
    if (tenantData?.config?.kpis?.csat_score) KPIS.push('csat');
    KPIS = [...KPIS, ...keys(tenantData?.config?.kpis ?? {})];

    setContextData({
      tenant: tenantData?.name ?? '',
      tenantLogo: tenantData?.logo ?? '',
      kpis: KPIS,
      groupID: tenantData?.config?.default_group_refkey ?? '',
      auditType: getAuditType(tenantData?.config?.audit_type) ?? [],
      showOriginalComment: tenantData?.config?.view_original_comments_default ?? false,
      thresholds: tenantData?.config?.thresholds,
      auditableStatus: tenantData?.config?.review_statuses ?? [],
      authType: tenantData?.auth_source,
      showLoom: tenantData?.config?.loom_integration ?? false,
      crm: tenantData?.crm,
      showCompositeScore: keys(tenantData?.config?.kpis)?.includes('composite_score'),
      triageBucket: tenantData?.config?.triage_boards ?? [],
      showOLDUI: false,
    });
  }, [tenantData]);

  if (isTenantLoading) {
    return (
      <Wrapper>
        <Spin size="large" spinning={true} />
      </Wrapper>
    );
  }

  if (isTenantError) {
    return;
    // render error page in case of tenant
  }

  return (
    <SettingsContextProvider>
      <UserContext.Provider value={{ ...contextData, setContextData }}>
        <Switch>
          {AUTH_TYPE.AWS === tenantData?.auth_source ? (
            <Authenticator.Provider>
              <Route component={withAwsAuth(withAuth(routes))} />
            </Authenticator.Provider>
          ) : (
            <>
              <Route exact path="/redirect" name="Redirect" component={RedirectScreen} />
              <Route component={withAuth(routes)} />
            </>
          )}
        </Switch>
      </UserContext.Provider>
    </SettingsContextProvider>
  );
};

export default App;
