import Constants from './constants';
import axios from 'axios';
import Axios from 'axios';
import { isError } from '@sentry/utils';
import * as Sentry from '@sentry/react';

var mixpanel = require('mixpanel-browser');

export const notifySentry = (rawError) => {
  if (!rawError) {
    return;
  }
  const errorItem = isError(rawError)
    ? rawError
    : new Error(`Received a non-error: ${JSON.stringify(rawError)}`);

  if (process.env?.NODE_ENV !== 'development') {
    Sentry.captureException(errorItem);
  }
};

export function getHostName() {
  let name = window.location.hostname;
  return name === 'localhost' || name.includes('192.168.')
    ? Constants.DEFAULT_CUSTOMER
    : name.split('.')[0];
}

//democs - demo-staging
//omnidian - omnidian-staging
//whoopcs
//coda
//abbyy
//swytch
//bandwidth

export function getTenant(forceUpdate = false) {
  if (
    localStorage.getItem('tenant') == null ||
    localStorage.getItem('VERSION_CODE') !== Constants.VERSION_CODE
  ) {
    let name = getHostName();
    Axios.post('tenants/config/', { hostname: name })
      .then((data) => {
        localStorage.setItem('tenant', JSON.stringify(data?.data ?? {}));
        localStorage.setItem('VERSION_CODE', Constants.VERSION_CODE);
      })
      .catch((err) => {
        notifySentry(err);
      });
  }
  return JSON.parse(localStorage.getItem('tenant'));
}

export function getUserRole() {
  Axios.get('myteam/roles/')
    .then((data) => {
      let resp = data?.data;

      localStorage.setItem('homepage', resp?.homepage);
      localStorage.setItem('permissions', JSON.stringify(resp?.permissions));
      localStorage.setItem('team_id', resp?.team_id ?? resp?.reference_id);
      localStorage.setItem('client_state', resp?.client_state);
      localStorage.setItem('current_refkey', resp?.current_refkey);
      localStorage.setItem('user_refkey', resp?.user_refkey);
      localStorage.setItem('role', resp?.role);
      localStorage.setItem('username', resp.username);
    })
    .catch((error) => {
      notifySentry(error);
    });
}

export function toHHMMSS(time) {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}

export function toMMSS(time) {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var minutes = Math.floor(sec_num / 60);
  var seconds = sec_num - minutes * 60;

  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return minutes + ':' + seconds;
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function formatDateTime(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  let hour = d.getHours()?.toString();
  let minute = d.getMinutes()?.toString();
  let second = d.getSeconds()?.toString();

  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return `${[year, month, day].join('-')} ${hour}:${minute}:${second}+00`;
}

export function formatDateUS(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('-');
}

const resources = {};

export function search(url, headers, body, callback) {
  let cancel;
  if (cancel) {
    // Cancel the previous request before making a new request
    cancel.cancel();
  }
  // Create a new CancelToken
  cancel = axios.CancelToken.source();
  if (resources[body.tag]) {
    return callback(false, resources[body.tag]);
  }

  axios({ method: 'post', url: url, headers, data: body, cancelToken: cancel.token })
    .then((res) => {
      const result = res.data.tags;
      resources[body.tag] = result;

      return callback(false, result);
    })
    .catch((error) => {
      notifySentry(error);
      return callback(error, false);
    });
}

const tickets = {};

export function searchTicket(url, id, headers, callback) {
  let cancel;
  if (cancel) {
    // Cancel the previous request before making a new request
    cancel.cancel();
  }
  // Create a new CancelToken
  cancel = axios.CancelToken.source();
  if (tickets[id]) {
    return callback(false, tickets[id]);
  }

  axios
    .get(url + id + '/', { headers: headers, cancelToken: cancel.token })
    .then((res) => {
      const result = res.data.tickets;
      tickets[id] = result;

      return callback(false, result);
    })
    .catch((error) => {
      notifySentry(error);
      return callback(error, false);
    });
}

export function checkAPICredentials(url, json, headers, callback) {
  //Freshdesk auth API
  let cancel;
  if (cancel) {
    // Cancel the previous request before making a new request
    cancel.cancel();
  }
  // Create a new CancelToken
  cancel = axios.CancelToken.source();

  axios
    .post(url, json, { headers: headers, cancelToken: cancel.token })
    .then((res) => {
      const result = res.data;
      if (result === 'Success') {
        return callback(false, result);
      } else {
        return callback(true, result);
      }
    })
    .catch((error) => {
      notifySentry(error);
      return callback(error, false);
    });
}

export function mixpanelTrack(action, text) {
  mixpanel.track(action, {
    version: Constants.VERSION_CODE,
    username: localStorage.getItem('username'),
    screen: window.location.pathname,
    element_text: text,
  });
}

export function behavioursDict(behaviours) {
  let result = {};
  behaviours.map((behaviour) => (result[behaviour.name] = behaviour));
  return result;
}

export function formatSecondstoTime(start, end) {
  let sec = parseInt(end - start);
  let minutes = parseInt(sec / 60);
  let seconds = sec % 60;
  return (minutes && minutes + ' minute ') + seconds + ' seconds';
}
