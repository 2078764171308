import * as React from 'react';

const RoundCancel = (props) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.9">
      <path
        d="M12.5757 13.4243C12.81 13.6586 13.1899 13.6586 13.4243 13.4243C13.6586 13.1899 13.6586 12.81 13.4243 12.5757L12.5757 13.4243ZM7.42426 6.57574C7.18995 6.34142 6.81005 6.34142 6.57574 6.57574C6.34142 6.81005 6.34142 7.18995 6.57574 7.42426L7.42426 6.57574ZM17.4 10C17.4 14.0869 14.0869 17.4 10 17.4V18.6C14.7496 18.6 18.6 14.7496 18.6 10H17.4ZM10 17.4C5.91309 17.4 2.6 14.0869 2.6 10H1.4C1.4 14.7496 5.25035 18.6 10 18.6V17.4ZM2.6 10C2.6 5.91309 5.91309 2.6 10 2.6V1.4C5.25035 1.4 1.4 5.25035 1.4 10H2.6ZM10 2.6C14.0869 2.6 17.4 5.91309 17.4 10H18.6C18.6 5.25035 14.7496 1.4 10 1.4V2.6ZM13.4243 12.5757L7.42426 6.57574L6.57574 7.42426L12.5757 13.4243L13.4243 12.5757Z"
        fill={props?.color}
      />
    </g>
  </svg>
);

export default RoundCancel;
