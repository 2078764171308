import * as React from 'react';

const SvgCaliberate = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.1294 20.3839H5.80938C4.59938 20.3839 3.60938 19.3939 3.60938 18.1839V5.80889C3.60938 4.59889 4.59938 3.60889 5.80938 3.60889H18.1844C19.3944 3.60889 20.3844 4.59889 20.3844 5.80889V18.1839C20.3844 19.3939 19.3944 20.3839 18.1294 20.3839ZM5.80938 4.43389C5.03938 4.43389 4.43438 5.03889 4.43438 5.80889V18.1839C4.43438 18.9539 5.03938 19.5589 5.80938 19.5589H18.1844C18.9544 19.5589 19.5594 18.9539 19.5594 18.1839V5.80889C19.5594 5.03889 18.9544 4.43389 18.1844 4.43389H5.80938Z"
      fill={props?.color}
    />
    <path d="M6.19141 6.5H7.01641V16.73H6.19141V6.5Z" fill={props?.color} />
    <path d="M5.47656 7.76611H7.73156V8.5911H5.47656V7.76611Z" fill={props?.color} />
    <path d="M11.6172 6.5H12.4422V16.73H11.6172V6.5Z" fill={props?.color} />
    <path d="M10.9023 10.7363H13.1573V11.5613H10.9023V10.7363Z" fill={props?.color} />
    <path d="M17.1172 6.5H17.9422V16.73H17.1172V6.5Z" fill={props?.color} />
    <path d="M16.4023 9.30518H18.6574V10.1302H16.4023V9.30518Z" fill={props?.color} />
  </svg>
);

export default SvgCaliberate;
