import React, { memo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: hsl(0, 0%, 20%);
  padding: 2px 8px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  /* outline: 0 !important; */
  transition: all 100ms;
  box-sizing: border-box;
  font-family: 'Lato';
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }

  &:visited {
    border-color: ${(props) => props.theme.primary};
  }
`;

const Input = styled.input`
  color: inherit;
  opacity: 1;
  width: 100%;

  font: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  -moz-appearance: textfield;

  &::placeholder {
    color: hsl(0, 0%, 50%);
    font-weight: 400;
    font-size: 14px;
  }

  &::-webkit-outer-spin-button {
    appearance: none;
  }

  &::-webkit-inner-spin-button {
    appearance: none;
  }
`;

const InputNumber = ({ className, placeholder, disabled, value, max, min, onChange, icon }) => {
  return (
    <Container>
      {icon && <img src={icon} width={13} alt={''} />}
      <Input
        className={`${className}`}
        type="number"
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        max={max}
        min={0}
        onChange={onChange}
      />
    </Container>
  );
};

InputNumber.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
};

export default memo(InputNumber);
