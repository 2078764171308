import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const { Header } = Modal;

export default styled(Header)`
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h2 {
    font-family: ${(props) => props.theme.primaryFont};
    font-style: normal;
    font-weight: bolder;
    font-size: 22px;
    line-height: 26px;
    color: ${(props) => props.theme.black};
    margin-bottom: 0;
  }

  > a {
    cursor: pointer;
  }
`;
