import styled from 'styled-components';

export default styled.div`
  max-width: 290px;
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  color: #ffffff;
  padding: 0;

  p {
    margin-bottom: 0;
  }
  > p {
    color: #fff;
    margin-bottom: 6px;
    font-size: 12px;

    &:last-child {
      margin-bottom: 0;
    }
    a {
      /* background: #5762d0; */
      color: #fff;
      padding: 2px 4px;
      /* border-radius: 3px;
      margin-left: 4px; */
      text-decoration: underline;
    }

    b {
      font-weight: 800;
    }
  }
`;
