import * as React from 'react';

const SvgMore = (props) => (
  <svg viewBox="0 0 18 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={2.5} cy={2.5} r={2} stroke="#4A4A4A" />
    <circle cx={8.93} cy={2.5} r={2} stroke="#4A4A4A" />
    <circle cx={15.355} cy={2.5} r={2} stroke="#4A4A4A" />
  </svg>
);

export default SvgMore;
