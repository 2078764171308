import * as React from 'react';

const SvgFlag2 = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.44107 2.09782C7.32379 2.15953 7.19978 2.26476 7.12938 2.36232L7.01245 2.52435L7.00186 9.92706C6.99601 13.9986 7.0044 18.0424 7.0205 18.9135C7.04953 20.4882 7.05023 20.4981 7.13754 20.6385C7.24181 20.8062 7.55752 20.9995 7.72804 21C7.96706 21.0007 8.26096 20.8092 8.38066 20.5748C8.45701 20.4252 8.45869 20.3699 8.48068 17.2205L8.50304 14.0182L10.2545 13.2212C16.0231 10.5963 19.5588 8.97528 20.1576 8.68096C20.2754 8.62305 20.3715 8.5666 20.3711 8.55549C20.3708 8.54439 20.1569 8.43911 19.896 8.32154C19.6351 8.20401 18.9856 7.9085 18.4527 7.6649C16.6466 6.83926 12.3462 4.87769 11.391 4.44378C10.8684 4.20633 10.0053 3.81374 9.47292 3.57134L8.50501 3.13057L8.46309 2.81427C8.40108 2.3463 8.28213 2.16392 7.96627 2.0525C7.74198 1.97335 7.66499 1.97999 7.44107 2.09782Z"
      fill={props?.color}
    />
    <path d="M8.50111 5.00023L17.001 8.5L8.50481 12.5057L8.50111 5.00023Z" fill="#2A2A2A" />
  </svg>
);

export default SvgFlag2;
