import * as React from 'react';

const SvgSearch = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.3333 17.6667C11.9604 17.6663 13.5406 17.1216 14.8222 16.1193L18.8519 20.149L20.1481 18.8528L16.1184 14.8232C17.1213 13.5414 17.6663 11.9608 17.6667 10.3333C17.6667 6.28992 14.3767 3 10.3333 3C6.28992 3 3 6.28992 3 10.3333C3 14.3767 6.28992 17.6667 10.3333 17.6667ZM10.3333 4.83333C13.3666 4.83333 15.8333 7.30008 15.8333 10.3333C15.8333 13.3666 13.3666 15.8333 10.3333 15.8333C7.30008 15.8333 4.83333 13.3666 4.83333 10.3333C4.83333 7.30008 7.30008 4.83333 10.3333 4.83333Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgSearch;
