import { Table } from 'antd';
import styled from 'styled-components';

export default styled(Table)`
  table {
    border-radius: ${(props) => props.theme.borderRadius};
    ${(props) =>
      !props?.allowOverflow &&
      `
      overflow: hidden;
      border: 1px solid #e2e2e2;
    `}
  }

  .ant-table-thead > tr > th {
    font-family: ${(props) => props.theme.primaryFont};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => props.theme.black};
    background-color: #f8f8f8;
    border-bottom: 1px solid #e2e2e2;
    padding: 16px;
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: ${(props) => props.theme.borderRadius} !important;
    }

    &:last-child {
      border-top-right-radius: ${(props) => props.theme.borderRadius} !important;
    }

    &::before {
      content: none !important;
    }
  }
  .ant-table-tbody > tr {
  }

  .ant-table-tbody > tr > td {
    background: #ffffff;
    font-family: ${(props) => props.theme.primaryFont};
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2e2f2f;
    padding: 16px;
  }

  button.ant-pagination-item-link {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  li.ant-pagination-item,
  li.ant-pagination-jump-next,
  li.ant-pagination-jump-prev {
    display: none;
  }

  /* li.ant-pagination-disabled {
    opacity: 0.5;
  } */

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: fit-content;
  }

  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin-right: 0;
  }

  div.ant-table-column-sorters {
    justify-content: initial;
  }
  span.ant-table-column-title {
    flex: initial;
    padding-right: 4px;
  }

  .ant-table-tbody .ant-table-cell {
    /* border-bottom: none; */
    border-bottom: 1px solid #e2e2e2;
  }

  .ant-table-tbody tr {
    &:last-child {
      .ant-table-cell {
        // border-bottom: none;
      }
    }
  }
`;
