import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  max-width: 200px;
  font-size: 13px;
`;

const InnerButton = styled.a`
  cursor: pointer !important;
  min-width: 80px;
  text-align: center;
  padding: 4px 8px;
  color: ${(props) => (props.active ? '#fff' : props.theme.primary)};
  background-color: ${(props) => (props.active ? props.theme.primary : '#fff')};
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: ${(props) => props.theme.borderRadius};
  font-family: ${(props) => props.theme.primaryFont};
  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  &:hover {
    text-decoration: none;
    color: ${(props) => (props.active ? '#fff' : props.theme.primary)};
    background-color: ${(props) => (props.active ? props.theme.primary : '#fff')};
  }
`;

const NamedToggle = ({ active = 'Skills', onClick, testid = '' }) => {
  return (
    <Container data-testid={testid}>
      <InnerButton
        onClick={() => {
          onClick('Skills');
          sessionStorage.setItem('namedToggle', 'Skills');
        }}
        active={active === 'Skills'}>
        Skills
      </InnerButton>
      <InnerButton
        onClick={() => {
          onClick('Behavior');
          sessionStorage.setItem('namedToggle', 'Behavior');
        }}
        active={active === 'Behavior'}>
        Behaviors
      </InnerButton>
    </Container>
  );
};

export default NamedToggle;
