import * as React from 'react';

const SvgCamera = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.25 18.875H5.75C5.25272 18.875 4.77581 18.6775 4.42417 18.3258C4.07254 17.9742 3.875 17.4973 3.875 17V9.5C3.875 9.00272 4.07254 8.52581 4.42417 8.17417C4.77581 7.82254 5.25272 7.625 5.75 7.625H8.3875L8.65625 6.54375C8.75794 6.1384 8.99213 5.77865 9.32165 5.52161C9.65116 5.26457 10.0571 5.12498 10.475 5.125H13.525C13.9429 5.12498 14.3488 5.26457 14.6784 5.52161C15.0079 5.77865 15.2421 6.1384 15.3438 6.54375L15.6125 7.625H18.25C18.7473 7.625 19.2242 7.82254 19.5758 8.17417C19.9275 8.52581 20.125 9.00272 20.125 9.5V17C20.125 17.4973 19.9275 17.9742 19.5758 18.3258C19.2242 18.6775 18.7473 18.875 18.25 18.875ZM5.75 8.875C5.58424 8.875 5.42527 8.94085 5.30806 9.05806C5.19085 9.17527 5.125 9.33424 5.125 9.5V17C5.125 17.1658 5.19085 17.3247 5.30806 17.4419C5.42527 17.5592 5.58424 17.625 5.75 17.625H18.25C18.4158 17.625 18.5747 17.5592 18.6919 17.4419C18.8092 17.3247 18.875 17.1658 18.875 17V9.5C18.875 9.33424 18.8092 9.17527 18.6919 9.05806C18.5747 8.94085 18.4158 8.875 18.25 8.875H15.125C14.9822 8.87916 14.8423 8.83429 14.7286 8.74787C14.6149 8.66144 14.5342 8.53867 14.5 8.4L14.1125 6.84375C14.0771 6.70624 13.9959 6.58489 13.8823 6.49969C13.7687 6.41449 13.6294 6.37051 13.4875 6.375H10.475C10.3331 6.37051 10.1938 6.41449 10.0802 6.49969C9.96663 6.58489 9.88543 6.70624 9.85 6.84375L9.5 8.4C9.46578 8.53867 9.3851 8.66144 9.27138 8.74787C9.15766 8.83429 9.01777 8.87916 8.875 8.875H5.75Z"
      fill={props?.color}
    />
    <path
      d="M12 16.375C11.3819 16.375 10.7777 16.1917 10.2638 15.8483C9.74994 15.505 9.3494 15.0169 9.11288 14.4459C8.87635 13.8749 8.81447 13.2465 8.93505 12.6403C9.05563 12.0342 9.35325 11.4773 9.79029 11.0403C10.2273 10.6033 10.7842 10.3056 11.3903 10.185C11.9965 10.0645 12.6249 10.1264 13.1959 10.3629C13.7669 10.5994 14.255 10.9999 14.5983 11.5138C14.9417 12.0277 15.125 12.6319 15.125 13.25C15.125 13.6604 15.0442 14.0667 14.8871 14.4459C14.7301 14.825 14.4999 15.1695 14.2097 15.4597C13.9195 15.7499 13.575 15.9801 13.1959 16.1371C12.8167 16.2942 12.4104 16.375 12 16.375ZM12 11.375C11.6292 11.375 11.2666 11.485 10.9583 11.691C10.65 11.897 10.4096 12.1899 10.2677 12.5325C10.1258 12.8751 10.0887 13.2521 10.161 13.6158C10.2334 13.9795 10.412 14.3136 10.6742 14.5758C10.9364 14.8381 11.2705 15.0166 11.6342 15.089C11.9979 15.1613 12.3749 15.1242 12.7175 14.9823C13.0601 14.8404 13.353 14.6 13.559 14.2917C13.765 13.9834 13.875 13.6208 13.875 13.25C13.875 12.7527 13.6775 12.2758 13.3258 11.9242C12.9742 11.5725 12.4973 11.375 12 11.375Z"
      fill={props?.color}
    />
    <path
      d="M17 11.375H16.375C16.2092 11.375 16.0503 11.3092 15.9331 11.1919C15.8158 11.0747 15.75 10.9158 15.75 10.75C15.75 10.5842 15.8158 10.4253 15.9331 10.3081C16.0503 10.1908 16.2092 10.125 16.375 10.125H17C17.1658 10.125 17.3247 10.1908 17.4419 10.3081C17.5592 10.4253 17.625 10.5842 17.625 10.75C17.625 10.9158 17.5592 11.0747 17.4419 11.1919C17.3247 11.3092 17.1658 11.375 17 11.375Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgCamera;
