const ArrowLeft = (props) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.48987 15.5228C8.77861 15.8198 9.25343 15.8265 9.55042 15.5377C9.84741 15.249 9.8541 14.7742 9.56536 14.4772L8.48987 15.5228ZM4.1665 10L3.62876 9.47719C3.34575 9.76828 3.34575 10.2317 3.62876 10.5228L4.1665 10ZM9.56536 5.52281C9.8541 5.22582 9.84741 4.75099 9.55042 4.46225C9.25343 4.17351 8.77861 4.1802 8.48987 4.47719L9.56536 5.52281ZM15.8332 10.75C16.2474 10.75 16.5832 10.4142 16.5832 10C16.5832 9.58579 16.2474 9.25 15.8332 9.25V10.75ZM9.56536 14.4772L4.70425 9.47719L3.62876 10.5228L8.48987 15.5228L9.56536 14.4772ZM4.70425 10.5228L9.56536 5.52281L8.48987 4.47719L3.62876 9.47719L4.70425 10.5228ZM4.1665 10.75H15.8332V9.25H4.1665V10.75Z"
      fill={props?.color}
    />
  </svg>
);

export default ArrowLeft;
