import * as React from 'react';

const SvgMinus = (props) => (
  <svg viewBox="0 0 19 3" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.7504 0.0625H2.24961C1.49043 0.0625 0.875 0.704883 0.875 1.5C0.875 2.29512 1.49043 2.9375 2.24961 2.9375H16.7504C17.5096 2.9375 18.125 2.29512 18.125 1.5C18.125 0.704883 17.5096 0.0625 16.7504 0.0625Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgMinus;
