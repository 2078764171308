import { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useRolesData } from 'API/auth';
import { isEmpty, filter } from 'lodash';
import { Spin } from 'antd';
import styled from 'styled-components';
import { UserContext } from 'index';
import Layout from 'Layout';
import { AUTH_TYPE } from 'Constants/others';
import { getCompanyName } from 'Helper/miscellaneous';
import { LEVELS } from 'Constants/role';

var mixpanel = require('mixpanel-browser');

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default (ComposedComponent) => {
  const WithAuth = (props) => {
    const { data, isLoading, isError } = useRolesData();
    const userContext = useContext(UserContext);

    const company_name = getCompanyName()?.replace('-qa', '');

    useEffect(() => {
      if (!isEmpty(data)) {
        localStorage.setItem('scorecard_id', data?.scorecard_id);
        localStorage.setItem('homepage', data?.homepage);
        localStorage.setItem('permissions', JSON.stringify(data?.permissions));
        localStorage.setItem('team_id', data?.team_id ?? data?.reference_id);
        localStorage.setItem('client_state', data?.client_state);
        localStorage.setItem('current_refkey', data?.current_refkey);
        localStorage.setItem('user_refkey', data?.user_refkey);
        localStorage.setItem('role', data?.role);
        localStorage.setItem('username', data?.username);
        localStorage.setItem('email', data?.email);

        mixpanel.identify(data?.username);
        mixpanel.people.set({
          // VERSION: Constants.VERSION_CODE,
          $name: data?.username,
          $email: data?.email, // only reserved properties need the $
          USER_ID: data?.username, // use human-readable names
        });

        // if (window?.pendo && (!window.pendo.isReady || !window?.pendo.isReady())) {
        //   window.pendo.initialize({
        //     visitor: {
        //       id: `${data?.email}_${company_name}`,
        //       email: data?.email,
        //       name: data?.username,
        //       // role,
        //     },
        //     account: {
        //       id: company_name,
        //       name: company_name,
        //     },
        //   });
        // }
      }
    }, [data]);

    useEffect(() => {
      if (data?.homepage === LEVELS.LEVEL_3 && props?.location?.pathname?.includes('/settings')) {
        props.history.push('/');
      }
    }, [data, props.location.pathname]);

    if (isLoading) {
      return (
        <Wrapper>
          <Spin size="large" spinning={true} />
        </Wrapper>
      );
    }
    if (isError) {
      // if sl redirect page else reload or aws login page
      if (userContext?.authType === AUTH_TYPE.SL) return <Redirect to={'/redirect'} />;
    }

    return (
      <UserContext.Provider
        value={{
          level: data?.homepage ?? '',
          user: data?.username ?? '',
          userID: data?.user_refkey,
          userRefID: data?.reference_id,
          auditStatus: data?.audit_status === 'Enabled' ? true : false,
          userTempID: data?.user_id ?? null,
          // permissions: filter(data?.permissions, (d) => d) ?? [],
          permissions: data?.permissions ?? [],
          scorecardID: userContext?.showCompositeScore
            ? null
            : data?.scorecard_id
            ? Number(data?.scorecard_id)
            : null,
          ...userContext,
        }}>
        <Layout>
          <ComposedComponent {...props} />
        </Layout>
      </UserContext.Provider>
    );
  };

  return WithAuth;
};
