import * as React from 'react';

const SvgNotify = (props) => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.75.75c-.06 0-.12.01-.181.036L3.562 4.405H1a.255.255 0 0 0-.25.26v4.67c0 .145.113.26.25.26h1.59c-.059.182-.09.374-.09.57 0 1.029.84 1.866 1.875 1.866.866 0 1.595-.587 1.81-1.381l6.385 2.566c.061.023.122.036.182.036a.51.51 0 0 0 .5-.52V1.27A.512.512 0 0 0 12.75.75ZM4.375 10.91a.748.748 0 0 1-.578-1.22l1.326.532a.752.752 0 0 1-.748.689Zm7.75.913L3.981 8.552 3.78 8.47H1.875V5.53H3.78l.201-.082 8.144-3.271v9.646Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgNotify;
