import { usePersistState } from 'CustomHooks';
import React, { useEffect, useState } from 'react';
/**
 * Context to store user settings
 * In sync with 'nav_collapse' in local storage
 * @returns *
 * {
 *  leftNavCollapse : Boolean value hat tells is left panel is collapsed or expanded
 *  setSeftNavCollapse : Setter function for setSeftNavCollapse
 *  resetSearchConfig : reset Search Screen configuration to defaults
 *  updateSearchConfig : update singe value in search config
 *  searchConfig : current search configuration
 * }
 *
 */

export const SettingsContext = React.createContext({
  leftNavCollapse: false,
  setSeftNavCollapse: () => {},
});

const searchConfigDefault = {
  searchText: '',
  currentPage: 1,
  selectedCard: 'total',
  sortData: null,
};

export default function SettingsContextProvider({ children }) {
  const [leftNavCollapse, setSeftNavCollapse] = usePersistState('nav_collapse', false);
  const [searchConfig, setSearchConfig] = useState(searchConfigDefault);
  const [agentScoresPageNo, setAgentScoresPageNo] = useState(1);
  const [agentScoreSortOrder, setAgentScoreSortOrder] = useState(null);

  const resetSearchConfig = () => setSearchConfig(searchConfigDefault);
  const resetAgentScoresConfig = () => {
    setAgentScoresPageNo(1);
    setAgentScoreSortOrder(null);
  };

  const updateSearchConfig = (key, value) => {
    if (searchConfig.hasOwnProperty(key)) {
      setSearchConfig((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        leftNavCollapse,
        setSeftNavCollapse,
        resetSearchConfig,
        updateSearchConfig,
        searchConfig,
        searchConfigDefault,
        setSearchConfig,
        agentScoresPageNo,
        setAgentScoresPageNo,
        resetAgentScoresConfig,
        agentScoreSortOrder,
        setAgentScoreSortOrder,
      }}>
      {children}
    </SettingsContext.Provider>
  );
}
