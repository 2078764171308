export const AUTH_PAGE_TYPE = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgotPassword',
  REQUIRE_NEW_PASSWORD: 'requireNewPassword',
};

export const FORGOT_PASSWORD_PAGE_VIEW = {
  SEND_VIEW: 'sendView',
  SUBMIT_VIEW: 'submitView',
  COMPLETE_VIEW: 'completeView',
};
