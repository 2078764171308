import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 4px 16px;
  border: 1px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  > a {
    cursor: pointer;
    margin-left: 8px;
    font-size: 11px;
    font-weight: bold;
  }
`;

const Tag = ({
  children,
  color,
  backgroundColor,
  isClosable = false,
  borderColor,
  onClose,
  testid = '',
}) => {
  return (
    <Container
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      data-testid={testid}>
      {children}
      {isClosable ? <a onClick={onClose}>x</a> : null}
    </Container>
  );
};

export default Tag;
