import * as React from 'react';

const SvgInfo = (props) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.5 11V7h-2v1h1v3H6v1h4v-1H8.5ZM8 4a.75.75 0 1 0 0 1.5A.75.75 0 0 0 8 4Z"
      fill="#000"
    />
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14ZM8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2Z" fill="#000" />
  </svg>
);

export default SvgInfo;
