import * as React from 'react';

const SvgEyehide = (props) => (
  <svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.3372 8.7875C18.6233 6.91927 17.3784 5.30071 15.756 4.13125L18.7497 1.13125L17.8685 0.25L1.24973 16.8687L2.13098 17.75L5.31848 14.5688C6.74125 15.398 8.35319 15.8478 9.99973 15.875C12.0369 15.7984 14.007 15.1265 15.6665 13.9424C17.326 12.7584 18.6021 11.114 19.3372 9.2125C19.3869 9.07518 19.3869 8.92482 19.3372 8.7875ZM9.99973 13.0625C9.13824 13.0621 8.29945 12.7862 7.60598 12.275L8.74973 11.1438C9.22398 11.4036 9.7697 11.5027 10.305 11.4261C10.8403 11.3495 11.3364 11.1014 11.7188 10.719C12.1012 10.3367 12.3492 9.84061 12.4258 9.30529C12.5024 8.76997 12.4033 8.22425 12.1435 7.75L13.2747 6.61875C13.715 7.2235 13.9795 7.93822 14.039 8.68391C14.0984 9.4296 13.9505 10.1772 13.6116 10.8441C13.2726 11.5109 12.7559 12.0711 12.1185 12.4626C11.4811 12.8541 10.7478 13.0617 9.99973 13.0625ZM2.83098 12.6312L5.95598 9.50625C5.93973 9.33802 5.93347 9.16897 5.93723 9C5.93889 7.92307 6.36743 6.89071 7.12894 6.1292C7.89045 5.3677 8.9228 4.93915 9.99973 4.9375C10.1648 4.93838 10.3296 4.94881 10.4935 4.96875L12.856 2.6125C11.9365 2.29669 10.9719 2.13206 9.99973 2.125C7.96256 2.20161 5.99248 2.87347 4.33299 4.05755C2.67349 5.24164 1.39733 6.88603 0.662234 8.7875C0.612589 8.92482 0.612589 9.07518 0.662234 9.2125C1.14225 10.4892 1.88056 11.653 2.83098 12.6312Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgEyehide;
