import * as React from 'react';

const SvgDelete = (props) => (
  <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 3.02734H12.289"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 3.02734H12.289"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.02734 3.02734V14.1716H11.2638V3.02734"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.02734 3.02734V14.1716H11.2638V3.02734"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.11328 5.05273V11.1314"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
    />
    <path
      d="M5.11328 5.05273V11.1314"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
    />
    <path
      d="M8.18848 5.05273V11.1314"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
    />
    <path
      d="M8.18848 5.05273V11.1314"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
    />
    <path
      d="M8.70133 3.02818V1.00195H5.10938V3.02818"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.70133 3.02818V1.00195H5.10938V3.02818"
      stroke={props?.color}
      strokeWidth="1.26389"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgDelete;
