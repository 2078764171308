import { useState } from 'react';
import { Container, Card, Row, Col, Image } from 'react-bootstrap';
import hidePasswordIcon from 'assets/images/hidepassword.svg';
import showPasswordIcon from 'assets/images/showpassword.svg';
import footerLogo from 'assets/images/emtropyfulllogo.svg';
import loader from 'assets/images/loading.png';
import THEME from 'Constants/theme';
import { getTenant } from 'utils/functions';

const RequireNewPassword = ({ onCompleteNewPassword, loading }) => {
  const [password, setPassword] = useState();
  const [showPassword, setPasswordStatus] = useState(false);

  return (
    <div className="logincontainer" style={{ background: '#eff2f6' }}>
      <Container fluid={true}>
        <Row>
          <Col style={{ paddinRight: 0, flex: '0 0 30%', maxWidth: '30%' }} sm="4"></Col>
          <Col
            sm="4"
            style={{ paddinRight: 0, flex: '0 0 40%', maxWidth: '40%' }}
            className="logincol">
            <img className="logo" style={{ height: 30 }} src={getTenant().logo} />

            <div>
              <form className="loginform">
                <Card style={{ borderRadius: THEME.borderRadius }}>
                  <Card.Body>
                    {
                      <Row>
                        <Col md="12" style={{ paddingRight: 50, paddingLeft: 50 }}>
                          <div>
                            <h2 style={{ color: THEME.black, fontFamily: THEME.primaryFont }}>
                              New Password
                            </h2>
                            <div style={{ marginTop: 40 }} className="input-password horizontal">
                              <input
                                style={{ outline: 'none' }}
                                disabled={loading ? true : false}
                                value={password}
                                className=""
                                id="password"
                                key="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter new password"
                              />
                              <Image
                                onClick={() => setPasswordStatus((prev) => !prev)}
                                src={showPassword ? hidePasswordIcon : showPasswordIcon}
                              />
                            </div>
                            <div style={{ marginTop: 10, width: '100%' }}>
                              <span
                                style={{
                                  justifyContent: 'center',
                                  color: THEME.primary,
                                  fontFamily: THEME.primaryFont,
                                }}
                                className="password-policy">
                                Use 8+ characters with a mix of uppercase, lower case letters, and
                                numbers.
                              </span>
                            </div>
                            <div className="flex items-center justify-between">
                              <button
                                style={{
                                  background: THEME.primary,
                                  borderColor: THEME.primary,
                                  boxShadow: 'unset',
                                  outline: 'none',
                                  fontFamily: THEME.primaryFont,
                                  borderRadius: THEME.borderRadius,
                                }}
                                disabled={loading ? true : false}
                                type="submit"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onCompleteNewPassword({ password });
                                }}>
                                <span className="loading-login">
                                  {loading ? (
                                    <Image className="icon rotation" src={loader} />
                                  ) : null}
                                  <span className="text">
                                    {loading
                                      ? 'Setting New Password...'
                                      : 'Set new password and login'}
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    }
                  </Card.Body>
                </Card>
              </form>
            </div>
          </Col>
          <Col sm="4" style={{ paddinRight: 0, flex: '0 0 30%', maxWidth: '30%' }}></Col>
        </Row>
      </Container>
      <span className="footer">
        {/* <Image src={footerLogo} /> */}
        <span className="text">&copy; {`${new Date().getFullYear()} SupportLogic`}</span>
      </span>
    </div>
  );
};

export default RequireNewPassword;
