import * as React from 'react';

const SvgEye = (props) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
      fill={props?.color}
    />
    <path
      d="M19.3372 9.7875C18.6021 7.88603 17.326 6.24164 15.6665 5.05755C14.007 3.87347 12.0369 3.20161 9.99973 3.125C7.96256 3.20161 5.99248 3.87347 4.33299 5.05755C2.67349 6.24164 1.39733 7.88603 0.662234 9.7875C0.612589 9.92482 0.612589 10.0752 0.662234 10.2125C1.39733 12.114 2.67349 13.7584 4.33299 14.9424C5.99248 16.1265 7.96256 16.7984 9.99973 16.875C12.0369 16.7984 14.007 16.1265 15.6665 14.9424C17.326 13.7584 18.6021 12.114 19.3372 10.2125C19.3869 10.0752 19.3869 9.92482 19.3372 9.7875ZM9.99973 14.0625C9.19625 14.0625 8.41081 13.8242 7.74273 13.3778C7.07466 12.9315 6.55395 12.297 6.24647 11.5547C5.93899 10.8123 5.85854 9.99549 6.01529 9.20745C6.17205 8.4194 6.55896 7.69553 7.12711 7.12738C7.69526 6.55923 8.41913 6.17231 9.20718 6.01556C9.99523 5.85881 10.8121 5.93926 11.5544 6.24674C12.2967 6.55422 12.9312 7.07492 13.3776 7.743C13.824 8.41107 14.0622 9.19651 14.0622 10C14.0606 11.0769 13.632 12.1093 12.8705 12.8708C12.109 13.6323 11.0767 14.0608 9.99973 14.0625Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgEye;
