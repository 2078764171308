export default {
  primary: '#5fc6f2',
  secondary: '#0EA9E0',
  lightSecondary: '#aba9f4',
  lightPrimary: '#E7F8FE',

  negative: '#FF4F4F',
  positive: '#00A599',

  success: 'rgb(13, 184, 103)',
  danger: 'rgb(245, 117, 77)',
  neutral: 'rgba(0, 0, 0, 0.2)',

  textColor: 'rgba(0, 0, 0, 0.6)',

  black: '#4A4A4A',
  white: '#ffffff',
  lightgray: '#cacccd',

  borderRadius: '4px',
  borderColor: '#E2E2E2',

  gray5: '#5a5a5b',
  gray6: '#626264',
  gray7: '#707683',
  gray8: '#808080',
  gray9: '#999999',

  /* Breakpoints */
  bpxz: '360px',
  bpxs: '576px',
  bpsm: '768px',
  bpmd: '992px',
  bplg: '1200px',
  bpxlg: '1366px',

  primaryFont: 'Avenir',
};

export const AUDITOR_COLOR_CODE = [
  { border: '#7CC674', background: '#F3FBF2' },
  { border: '#FF8493', background: '#FEF2F2' },
  { border: '#8481DD', background: '#F1F3FC' },
  { border: '#F2B02D', background: '#FEFAEC' },
  { border: '#519DE9', background: '#F1F8FE' },
  { border: '#E857FF', background: '#FEF4FF' },
  { border: '#3BC6B9', background: '#F1FCF9' },
  { border: '#CCE635', background: '#FCFEE7' },
  { border: '#44BCFF', background: '#EFF8FF' },
  { border: '#FF5959', background: '#FFF0F0' },
];
