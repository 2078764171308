import * as React from 'react';

const SvgNetwork = (props) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 .5a.3.3 0 0 0-.3.3v14.4a.3.3 0 0 0 .3.3h3.2a.3.3 0 0 0 .3-.3V.8a.3.3 0 0 0-.3-.3H12ZM6.398 4.5a.3.3 0 0 0-.3.3v10.4a.3.3 0 0 0 .3.3h3.2a.3.3 0 0 0 .3-.3V4.8a.3.3 0 0 0-.3-.3h-3.2ZM.8 9.3a.3.3 0 0 0-.3.3v5.6a.3.3 0 0 0 .3.3H4a.3.3 0 0 0 .3-.3V9.6a.3.3 0 0 0-.3-.3H.8Z"
      stroke="#000"
    />
  </svg>
);

export default SvgNetwork;
