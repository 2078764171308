import * as React from 'react';

const SvgPlay = (props) => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.508.19A1 1 0 0 0 0 1.05v9.522a1 1 0 0 0 1.508.862l8.07-4.761a1 1 0 0 0 0-1.723L1.509.19Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgPlay;
