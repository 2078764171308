import * as React from 'react';

const SvgDashboard = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 5H10.5417V12.125H5V5Z"
      stroke={props?.color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7109 5H19.2526V8.95833H13.7109V5Z"
      stroke={props?.color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7109 12.125H19.2526V19.25H13.7109V12.125Z"
      stroke={props?.color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15.2905H10.5417V19.2489H5V15.2905Z"
      stroke={props?.color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgDashboard;
