import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';
import { isEmpty, lowerCase } from 'lodash';
import theme from 'Constants/theme';
import NextPrev from 'NewComponents/nextPrev';

const { Checkbox } = InputGroup;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.5em;
  min-height: 24px;

  /* border: 1px solid; */
  /* width: max-content; */

  > div {
    display: flex;
    align-items: center;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Dot = styled.span`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: ${(props) => props.color};
  margin-left: 1em;
`;

const Label = styled.label`
  margin: 0;
  margin-left: 1em;
  font-size: 12px;
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props) => props.theme.black};
  font-weight: 500;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const ColoredCheckBox = ({
  label,
  count,
  sentiment,
  checked,
  onFilterApply,
  exData,
  selected,
  setHighlightedCommentID,
  setHoveredComment,
  comments,
  setHighlightWord,
  name,
  nextPrevCurrent,
  setNextPrev,
  setMessageID,
  onFeebackNextPrev,
  isBlur,
}) => {
  const [status, setStatus] = useState(() => checked);

  useEffect(() => {
    if (isEmpty(selected) || selected.length === 0 || label !== selected[0]) setStatus(false);
  }, [selected]);

  const isDisabled = count === 0 ? true : false;

  let colorCode = theme.neutral;

  if (sentiment === 'Positive') {
    if (exData.sentiment.negative === 0) colorCode = theme.success;
    else colorCode = '#F2B75E';
  } else if (sentiment === 'Negative') colorCode = theme.danger;
  else if (sentiment === 'Unobserved') colorCode = theme.neutral;
  else if (isEmpty(sentiment) && !isDisabled) colorCode = theme.danger;

  if (comments.length === 0) return null;

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        if (isDisabled) return;

        onFilterApply(
          label,
          comments?.[0]?.comment_id,
          comments?.[0]?.words,
          lowerCase(name),
          comments?.[0]?.messageID
        );

        setStatus((prevState) => !prevState);
      }}>
      <div>
        {!isBlur && (
          <Checkbox
            style={{ outline: 'none' }}
            disabled={isDisabled}
            checked={status}
            onChange={(e) => {}}
          />
        )}

        {!isBlur && <> {lowerCase(name) !== 'feedback' && <Dot color={colorCode}></Dot>}</>}

        <Label disabled={isDisabled}>{label}</Label>
      </div>

      {status && (
        <NextPrev
          totalCount={comments.length}
          current={nextPrevCurrent}
          onNext={(id) => {
            onFeebackNextPrev(id, comments);
          }}
          onPrev={(id) => {
            onFeebackNextPrev(id, comments);
          }}
        />
      )}
    </Container>
  );
};

export default ColoredCheckBox;
