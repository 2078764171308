import React from 'react';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
  background: #ff767563;
  padding: 1em 2em;
  border-radius: 4px;
  border: 1px solid #d63031;
  font-weight: bold;
  color: #444;
`;

const Error = ({ error }) => {
  return <ErrorWrapper>{error}</ErrorWrapper>;
};

export default Error;
