import { notification } from 'antd';

export const handleError = (message, statusCode) => {
  if (statusCode === 401) {
    window.location.reload();
    return;
  }

  notification.error({
    message: message ?? 'Server Error',
  });
};
