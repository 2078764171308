import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

const getSavedData = (key, initialValue) => {
  if (sessionStorage.getItem(key) !== 'undefined') {
    const savedData = JSON.parse(sessionStorage.getItem(key));

    if (!isEmpty(savedData)) return savedData;
    if (initialValue instanceof Function) return initialValue();
    return initialValue;
  }
};

const usePersistState = (key, initialValue) => {
  const [value, setValue] = useState(() => getSavedData(key, initialValue));

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default usePersistState;
