import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const { Footer } = Modal;

export default styled(Footer)`
  border-top: 0;

  > button {
    margin-right: 1.5em;

    &:last-child {
      margin-right: 0;
    }
  }
`;
