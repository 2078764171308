import { upperCase, capitalize, trim, map, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import Popover, { PopoverContent } from 'UI/Popover';
import constants from 'utils/constants';
import { getHostName } from 'utils/functions';

export const trimText = (text = '', length, enablePopover = true) => {
  if (text?.length > length) {
    if (enablePopover) {
      return (
        <Popover content={<PopoverContent>{text}</PopoverContent>}>
          {`${text.slice(0, length)} ...`}
        </Popover>
      );
    } else {
      return <span> {`${text.slice(0, length)} ...`}</span>;
    }
  } else return text;
};

export const getCharName = (name = '') => {
  return upperCase(` ${name?.split(' ')?.[0]?.[0] ?? ''}${name?.split(' ')?.[1]?.[0] ?? ''}`);
};

export const getFirstName = (name = '') => {
  return capitalize(trim(name)?.split(' ')?.[0]);
};

export const arrangeDropdownData = (
  data = [],
  temp = [],
  labelField = 'name',
  valueField = 'name'
) => {
  map(data, (d) => temp.push({ label: d?.[labelField], value: d?.[valueField] }));
  return temp;
};

export const getFormattedDate = (data) => dayjs(data).format("DD MMM, 'YY");

export const getTime = (data) => {
  let temp = new Date(data);

  let hours = temp?.getHours()?.toString();
  let minutes = temp?.getMinutes()?.toString();

  if (hours.length === 1) hours = `0${hours}`;
  if (minutes.length === 1) minutes = `0${minutes}`;

  return `${hours}:${minutes}`;
};

export const arrageFilterDataToObject = (data = [], temp = {}) => {
  map(data, (d) => (temp = { ...temp, [d?.name]: d?.value }));
  return temp;
};

export const shortName = (text = '') => {
  let temp = text?.split(' ');
  let srt = '';

  map(temp, (t, i) => {
    if (i === 0 || isEmpty(t)) return;
    srt += t[0];
  });

  if (srt === 'undefined') srt = '';

  return `${temp?.[0]} ${upperCase(srt)}`;
};

export const gethostName = () => {
  let host = constants.DEFAULT_CUSTOMER; // localhost
  if (process.env.NODE_ENV !== 'development') {
    host = window.location.host;
  }
  return host;
};

export const getCompanyName = getHostName;

export const getModifiedNumber = (numberWithDecimal) => numberWithDecimal;
// String(Math.round(numberWithDecimal * 10) / 10);
