import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  height: 100%;
  z-index: 1000;
  margin-top: 0px;
  padding-top: 70px;
  padding-bottom: 0px;
`;

export const Header = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  /* justify-content: space-between; */
  flex: 0 0 auto;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 70px;
  background: rgb(42, 42, 42);

  flex-direction: ${(props) => (props.collapsed ? 'column' : 'row')};
  > a {
    display: flex;
    padding-left: ${(props) => (props.collapsed ? '0px' : '10px')};

    > svg {
      width: ${(props) => (props.collapsed ? '38px' : '110px')};
      height: ${(props) => (props.collapsed ? 'auto' : '39px')};
      color: rgb(255, 255, 255);
    }
  }
`;

export const CollapseWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: -13px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(226, 226, 226);
    transition: all 0.3s ease 0s;
    cursor: pointer;

    &:hover {
      background: rgb(231, 248, 254);
    }

    > svg {
      width: 15px;
      height: 15px;
      transition: all 0.3s ease 0s;
      transform: ${(props) => (props.collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }
`;

export const NavWrapper = styled.div`
  width: ${(props) => (props.collapsed ? '50px' : '225px')};
  background: rgb(42, 42, 42);
  transition: all 0.3s ease 0s;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 3001;
`;

export const InbulitMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${(props) => (props.collapsed ? '16px' : '25px')};
  padding-bottom: ${(props) => (props.collapsed ? '16px' : '0px')};
  border-color: ${(props) => (props.collapsed ? props.theme.black : 'transparent')};
  border-bottom-width: ${(props) => (props.collapsed ? '1px' : '0px')};
  border-bottom-style: solid;
  flex: 0 0 auto;

  &:last-child {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: unset;
  color: rgb(255, 255, 255);
  padding: 2px 0px;
  height: unset;
  cursor: default;

  > div {
    &:first-child {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      margin-left: 15px;
      position: relative;
      opacity: 1;

      > span {
        font-weight: 500;
        font-family: 'Avenir', sans-serif;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        visibility: visible;
        opacity: 1;
        position: relative;
        color: rgb(155, 155, 155);
        text-transform: uppercase;
        word-break: break-word;
      }
    }

    &:last-child {
      position: relative;
      top: -1px;
      width: 26px;
      height: 26px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border-radius: 50%;
      background: transparent;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      margin-right: 10px;
      transform-origin: 50% 50%;
      transform: ${(props) => (props.headerVisible ? 'rotate(0deg)' : 'rotate(90deg)')};

      &:hover {
        background: rgb(83, 83, 83);
        > span {
          border-color: transparent transparent transparent rgb(255, 255, 255);
        }
      }
      > span {
        position: relative;
        right: -1px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 0px 5px 6px;
        border-color: transparent transparent transparent rgb(148, 148, 148);
        transition: all 0.3s ease 0s;
      }
    }
  }
`;

export const MenuList = styled.div`
  position: relative;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }

  /* > a + div {
    left: ${(props) => (props.visible ? '100px' : '0px')} !important;
  } */

  a {
    font-weight: 500;
    font-family: Avenir, sans-serif;
    font-size: 14px;
    color: ${(props) => (props.active ? 'rgb(95, 198, 242)' : 'rgb(255, 255, 255)')} !important;
    height: ${(props) => (props.collapsed ? '40px' : '30px')};
    display: flex;

    align-items: center;

    justify-content: ${(props) => (props.collapsed ? 'center' : 'unset')};
    transition: all 0.3s ease 0s;
    position: relative;
    border-left: 3px solid ${(props) => (props.active ? 'rgb(95, 198, 242)' : 'transparent')};
    padding-left: ${(props) => (props.collapsed ? '2px' : '22px')};
    padding-right: 5px;
    background-color: ${(props) => (props.active ? 'rgba(95, 198, 242, 0.25)' : 'inherit')};

    > div {
      width: 35px;
      margin-right: ${(props) => (props.collapsed ? '0px' : '5px')};
      height: 23px;
      color: rgb(255, 255, 255);
      opacity: 1;
      margin-left: unset;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      transition: color 0.3s ease 0s;
      > svg {
        height: inherit;
      }
    }

    > span {
      display: flex;
      opacity: 1;
      > div {
        display: inline-block;
        max-width: 150px;
        > div {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          /* color: rgb(255, 255, 255); */
        }
      }
    }
  }
`;

export const List = styled(Link)`
  font-weight: 500;
  font-family: Avenir, sans-serif;
  font-size: 14px;
  color: ${(props) => (props.active ? 'rgb(95, 198, 242)' : props?.theme.black)} !important;
  height: ${(props) => (props.collapsed ? '40px' : '30px')};
  display: flex;

  align-items: center;

  justify-content: ${(props) => (props.collapsed ? 'center' : 'unset')};
  transition: all 0.3s ease 0s;
  position: relative;
  border-left: 3px solid ${(props) => (props.active ? 'rgb(95, 198, 242)' : 'transparent')};
  background-color: ${(props) => (props.active ? 'rgba(95, 198, 242, 0.25)' : 'inherit')};

  > div {
    width: 35px;
    margin-right: ${(props) => (props.collapsed ? '0px' : '5px')};
    height: 23px;
    color: rgb(255, 255, 255);
    opacity: 1;
    margin-left: unset;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    transition: color 0.3s ease 0s;
    > svg {
      height: inherit;
    }
  }

  > span {
    display: flex;
    opacity: 1;
    > div {
      display: inline-block;
      max-width: 150px;
      > div {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const ClickableLink = styled.a`
  cursor: pointer;
`;
