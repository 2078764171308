import * as React from 'react';

const SvgFilter = (props) => (
  <svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#filter_svg__a)" fill="#4A4A4A">
      <path d="M13.5 16.875v-2.306c1.294-.282 2.25-1.407 2.25-2.757s-.956-2.474-2.25-2.756V1.125h-1.125v7.931c-1.294.282-2.25 1.407-2.25 2.757s.956 2.474 2.25 2.756v2.306H13.5Zm-2.25-5.063c0-.956.731-1.687 1.688-1.687.956 0 1.687.731 1.687 1.688 0 .956-.731 1.687-1.688 1.687a1.657 1.657 0 0 1-1.687-1.688ZM4.5 1.125v2.306c-1.294.281-2.25 1.407-2.25 2.756 0 1.35.956 2.475 2.25 2.757v7.931h1.125V8.944c1.294-.282 2.25-1.407 2.25-2.757s-.956-2.475-2.25-2.756V1.125H4.5Zm2.25 5.063c0 .956-.731 1.687-1.688 1.687a1.657 1.657 0 0 1-1.687-1.688c0-.956.731-1.687 1.688-1.687.956 0 1.687.731 1.687 1.688Z" />
    </g>
    <defs>
      <clipPath id="filter_svg__a">
        <path fill="#fff" transform="rotate(90 9 9)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgFilter;
