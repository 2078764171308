import { useState } from 'react';
import THEME from 'Constants/theme';
import { Image } from 'react-bootstrap';
import loader from 'assets/images/loading.png';
import { AUTH_PAGE_TYPE } from 'Constants/auth';

const SendView = ({ loading = false, setAuthType, onSubmit }) => {
  const [username, setUsername] = useState('');

  return (
    <div>
      <h2 style={{ color: THEME.black, fontFamily: THEME.primaryFont }}>Reset your password</h2>
      <div className="input-password" style={{ marginTop: 40 }}>
        <input
          style={{ outline: 'none' }}
          disabled={loading ? true : false}
          className=""
          id="username1"
          key="username"
          name="username"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          type="text"
          placeholder="Enter your email or username"
          required
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          style={{
            background: THEME.primary,
            borderColor: THEME.primary,
            boxShadow: 'unset',
            outline: 'none',
            fontFamily: THEME.primaryFont,
            borderRadius: THEME.borderRadius,
          }}
          disabled={loading ? true : false}
          type="submit"
          data-testid={'send-code-button'}
          onClick={(e) => {
            e.preventDefault();
            onSubmit({ username });
          }}>
          <span className="loading-login">
            {loading ? <Image className="icon rotation" src={loader} /> : null}
            <span className="text">{loading ? 'Sending Code' : 'Send Code'}</span>
          </span>
        </button>
      </div>
      <div style={{ width: '100%' }}>
        <span
          style={{
            justifyContent: 'center',
            color: THEME.primary,
            fontFamily: THEME.primaryFont,
          }}
          onClick={() => setAuthType(AUTH_PAGE_TYPE.LOGIN)}
          className="blue-small-text"
          data-testid={'back-to-login-button'}>
          Back to Log In
        </span>
      </div>
    </div>
  );
};

export default SendView;
