import * as React from 'react';

const SvgPin = (props) => (
  <svg viewBox="0 0 11 11" fill="none" {...props}>
    <g clip-path="url(#clip0_3824_32608)">
      <path
        d="M6.76284 0.497071C6.80801 0.49699 6.85276 0.505814 6.89452 0.523037C6.93628 0.54026 6.97423 0.565545 7.00622 0.597446L10.4093 4.00057C10.4738 4.06503 10.51 4.15245 10.51 4.2436C10.51 4.33475 10.4738 4.42217 10.4093 4.48663C10.0793 4.81663 9.67234 4.89088 9.37603 4.89088C9.25434 4.89088 9.14572 4.87851 9.05978 4.86407L6.90515 7.0187C6.96187 7.24723 6.99868 7.48025 7.01515 7.71513C7.04678 8.19776 6.99315 8.87495 6.52015 9.34795C6.45569 9.41239 6.36827 9.44859 6.27712 9.44859C6.18597 9.44859 6.09855 9.41239 6.03409 9.34795L4.08915 7.4037L1.90153 9.59132C1.76747 9.72538 1.06347 10.2114 0.929404 10.0774C0.795341 9.94332 1.2814 9.23864 1.41547 9.10526L3.60309 6.91763L1.65884 4.9727C1.5944 4.90823 1.5582 4.82082 1.5582 4.72967C1.5582 4.63851 1.5944 4.5511 1.65884 4.48663C2.13184 4.01363 2.80903 3.95932 3.29165 3.99163C3.52654 4.00809 3.75956 4.0449 3.98809 4.10163L6.14272 1.9477C6.12473 1.843 6.11553 1.73699 6.11522 1.63076C6.11522 1.33513 6.18947 0.928133 6.52015 0.597446C6.58458 0.533186 6.67185 0.49709 6.76284 0.497071Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_3824_32608">
        <rect width={props.width} height={props.height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPin;
