import * as React from 'react';

const SvgMail = (props) => (
  <svg viewBox="0 0 289 180" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 63.181 254.357 99.8l34.131 34.443L0 63.181Z" fill="#B3B0E6" />
    <path opacity={0.6} d="M0 63.181 254.357 99.8l34.131 34.443L0 63.181Z" fill="#fff" />
    <path d="M0 63.181 254.357 99.8l-11.804 49.807L0 63.181Z" fill="#B3B0E6" />
    <path opacity={0.2} d="M0 63.181 254.357 99.8l-11.804 49.807L0 63.181Z" fill="#000" />
    <path
      d="m237.331 5.519 14.761 69.176a6.973 6.973 0 0 1-5.355 8.268L114.989 111.08a6.983 6.983 0 0 1-8.268-5.355L91.949 36.548a6.985 6.985 0 0 1 5.367-8.268L229.063.163a6.984 6.984 0 0 1 8.268 5.367V5.52Z"
      fill="#B3B0E6"
    />
    <path
      opacity={0.4}
      d="m237.331 5.519 14.761 69.176a6.973 6.973 0 0 1-5.355 8.268L114.989 111.08a6.983 6.983 0 0 1-8.268-5.355L91.949 36.548a6.985 6.985 0 0 1 5.367-8.268L229.063.163a6.984 6.984 0 0 1 8.268 5.367V5.52Z"
      fill="#000"
    />
    <path
      d="m234.084.99-44.016 54.035a28.375 28.375 0 0 1-34.756 7.42L93.064 31.092a6.94 6.94 0 0 1 4.252-2.823L229.063.152a6.952 6.952 0 0 1 5.021.837ZM0 63.181l209.794 49.863 32.759 36.563L0 63.181Z"
      fill="#B3B0E6"
    />
    <path opacity={0.6} d="m0 63.181 209.794 49.863 32.759 36.563L0 63.181Z" fill="#fff" />
    <path
      d="m76.529 111.242-.564.963L99.31 125.88l.564-.962-23.346-13.676ZM110.183 130.947l-.564.962 8.395 4.918.564-.963-8.395-4.917ZM0 63.181l209.794 49.863-40.78 49.149L0 63.181ZM280.61 180a2.218 2.218 0 0 1-.825-.156l-34.165-13.523a2.231 2.231 0 0 1 1.641-4.151l34.175 13.523a2.23 2.23 0 0 1 1.371 2.496A2.23 2.23 0 0 1 280.61 180Z"
      fill="#B3B0E6"
    />
    <path
      opacity={0.3}
      d="M280.61 180a2.218 2.218 0 0 1-.825-.156l-34.165-13.523a2.231 2.231 0 0 1 1.641-4.151l34.175 13.523a2.23 2.23 0 0 1 1.371 2.496A2.23 2.23 0 0 1 280.61 180Z"
      fill="#000"
    />
    <path
      d="M278.592 160.407a2.114 2.114 0 0 1-.815-.167l-18.354-7.308a2.218 2.218 0 0 1-.737-.47 2.237 2.237 0 0 1-.532-2.431 2.225 2.225 0 0 1 1.195-1.23 2.226 2.226 0 0 1 1.714-.02l18.354 7.275a2.233 2.233 0 0 1 1.261 2.89 2.228 2.228 0 0 1-2.086 1.461Z"
      fill="#B3B0E6"
    />
    <path
      opacity={0.5}
      d="M278.592 160.407a2.114 2.114 0 0 1-.815-.167l-18.354-7.308a2.218 2.218 0 0 1-.737-.47 2.237 2.237 0 0 1-.532-2.431 2.225 2.225 0 0 1 1.195-1.23 2.226 2.226 0 0 1 1.714-.02l18.354 7.275a2.233 2.233 0 0 1 1.261 2.89 2.228 2.228 0 0 1-2.086 1.461Z"
      fill="#fff"
    />
    <path
      d="M240.221 179.71a2.34 2.34 0 0 1-.814-.156l-33.328-13.233a2.218 2.218 0 0 1-.737-.47 2.222 2.222 0 0 1-.687-1.571 2.221 2.221 0 0 1 .63-1.594 2.235 2.235 0 0 1 2.435-.516l33.327 13.188a2.23 2.23 0 0 1 1.371 2.496 2.226 2.226 0 0 1-2.197 1.811v.045Z"
      fill="#B3B0E6"
    />
    <path
      opacity={0.3}
      d="M240.221 179.71a2.34 2.34 0 0 1-.814-.156l-33.328-13.233a2.218 2.218 0 0 1-.737-.47 2.222 2.222 0 0 1-.687-1.571 2.221 2.221 0 0 1 .63-1.594 2.235 2.235 0 0 1 2.435-.516l33.327 13.188a2.23 2.23 0 0 1 1.371 2.496 2.226 2.226 0 0 1-2.197 1.811v.045Z"
      fill="#000"
    />
  </svg>
);

export default SvgMail;
