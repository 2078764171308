import { Avatar } from 'antd';
import styled from 'styled-components';

export default styled(Avatar)`
  background-color: #fff;
  width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Avenir';
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #5fc6f2;
  color: #3a3a3a;
`;
