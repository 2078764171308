import * as React from 'react';

const SvgFileEye = (props) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 18H3.99999C2.89542 18 1.99999 17.1045 2 16L2.00008 4.00002C2.00008 2.89545 2.89551 2.00003 4.00008 2.00003H13.0003C14.1049 2.00003 15.0003 2.89546 15.0003 4.00003V8.00003M14.5 14.4575V14.4049M5.50031 6.00003H11.5003M5.50031 9.00003H11.5003M5.50031 12H8.50031M18 14.5C18 14.5 17.1699 16.9498 14.5 16.907C11.8301 16.8642 11 14.5 11 14.5C11 14.5 11.7965 12.0075 14.5 12.0075C17.2035 12.0075 18 14.5 18 14.5Z"
      stroke={props?.color}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgFileEye;
