import { useQuery } from 'react-query';
import { handleError } from 'Helper/utils';
import { filter, map } from 'lodash';
import { arrageFilterDataToObject } from 'Helper/miscellaneous';
import { Request } from './request';
import queryString from 'query-string';

export const useLabelsData = () =>
  useQuery('Labels', () => Request.get(`labels/`), {
    refetchOnWindowFocus: false,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
    select: (data) => data,
  });

export const useFiltersData = () =>
  useQuery('Filters', () => Request.get(`myteam/custom-filters/`), {
    refetchOnWindowFocus: false,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
    select: (data) => arrageFilterDataToObject(data?.filters),
  });

export const useGroupsData = (CB) =>
  useQuery('Groups', () => Request.get(`myteam/groups/`), {
    refetchOnWindowFocus: false,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
    onSuccess: (data) => CB(data),
    select: (data) => data,
  });

export const useSourceTagsData = (query, CB) =>
  useQuery(
    ['Source Tags,', { ...query }],
    () => Request.get(`myteam/filters_tags/?${queryString.stringify({ ...query })}`),
    {
      refetchOnWindowFocus: false,
      onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
      select: (data) => data?.filters ?? {},
      enabled: !!query?.search,
      onSuccess: (data) => CB(data),
    }
  );

export const useGroupsDetail = (teamID) =>
  useQuery('Groups Detail', () => Request.get(`myteam/managers_leads`), {
    refetchOnWindowFocus: false,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
    enabled: !!teamID,
    select: (data) => {
      let filteredData = [];
      map(data, (d) => {
        map(d, (k) => {
          if (k?.refkey === teamID) filteredData = d;
        });
      });
      return filteredData;
    },
  });

export const useGroupsSubGroupResponderData = (enabled = true) =>
  useQuery('Groups Sub-Group Responder', () => Request.get(`qa-group-filters/`), {
    refetchOnWindowFocus: false,
    enabled: enabled,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
  });

export const useReviewerData = () =>
  useQuery('Reviewer', () => Request.get(`qa-reviewer/`), {
    refetchOnWindowFocus: false,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
    select: (data) => data?.Reviewer ?? [],
  });

export const useAllUsersData = () =>
  useQuery('All Users', () => Request.get(`myteam/allusers/`), {
    refetchOnWindowFocus: false,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
  });

export const useLoomToken = () =>
  useQuery('Loom Token', () => Request.get(`tenants/loom-token/`), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onError: (err) => handleError(err?.response?.data?.error?.message, err?.response?.status),
  });
