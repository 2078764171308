import * as React from 'react';

const SvgTriage = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 18H9V10.3182V5.70909C9 5.31747 9.26863 5 9.6 5H14.4C14.7314 5 15 5.31747 15 5.70909V12.6818V18Z"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4 18H15V14.1C15 13.7686 15.2686 13.5 15.6 13.5H20.4C20.7314 13.5 21 13.7686 21 14.1V17.4C21 17.7314 20.7314 18 20.4 18Z"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 18V12.1C9 11.7686 8.73137 11.5 8.4 11.5H3.6C3.26863 11.5 3 11.7686 3 12.1V17.4C3 17.7314 3.26863 18 3.6 18H9Z"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgTriage;
