import * as React from 'react';

const SvgPercent = (props) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.442.558a.624.624 0 0 1 0 .884l-10 10a.626.626 0 0 1-.884-.884l10-10a.624.624 0 0 1 .884 0ZM2.5 4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      fill="#000"
    />
  </svg>
);

export default SvgPercent;
