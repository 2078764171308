export const LEVELS = {
  LEVEL_1: 'ADMIN_CONFIG',
  LEVEL_2: 'CUSTOM_CONFIG',
  LEVEL_3: 'AGENT_CONFIG',
};

export const ALL_USER_ACCESS_PERMISSION = 'ACCESS_ALL_TEAMS_DATA';

export const TENANT = {
  CODA: 'coda',
  OMNIDIAN: 'omnidian',
  WHOOP: 'whoop',
};
