import {
  Dashboard,
  Triage,
  Assignment,
  Create,
  Flag2,
  Inbox,
  Outbox,
  UserGearIcon,
  Idcard,
  Caliberate,
  User2,
  Usergroup,
  Roles,
  Note,
  Camera,
  Search,
  QCIcon,
} from 'UI/Icons';
import THEME from 'Constants/theme';
import LineReportIcon from 'UI/Icons/LineReportIcon';

export const NAV_DATA = ({ active }) => {
  return [
    {
      header: 'Overview',
      submenu: [
        {
          name: 'Dashboard',
          icon: <Dashboard color={active === 'Dashboard' ? THEME.primary : '#fff'} />,
          link: '/home/dashboard',
          testid: 'dashboard-menu',
        },
        {
          name: 'Tickets of Interest',
          icon: <Triage color={active === 'Triage' ? THEME.primary : '#fff'} />,
          link: '/home/triage',
          testid: 'tickets-of-interest-menu',
        },

        {
          name: 'Advanced Search',
          icon: <Search color={active === 'Advanced Search' ? THEME.primary : '#fff'} />,
          link: '/home/search',
          testid: 'advanced-search-menu',
        },
      ],
    },

    {
      header: 'QA',
      submenu: [
        {
          name: 'Quality Console',
          icon: <QCIcon color={active === 'Quality Console' ? THEME.primary : '#fff'} />,
          link: '/home/qa/console',
        },
        {
          name: 'Assignments',
          icon: <Assignment color={active === 'Assignments' ? THEME.primary : '#fff'} />,
          link: '/home/qa/assignment',
          testid: 'assignments-menu',
        },
        {
          name: 'Disputes',
          icon: <Flag2 color={active === 'Disputes' ? THEME.primary : '#fff'} />,
          link: '/home/qa/dispute',
          testid: 'disputes-menu',
        },
        {
          name: 'Create Assignments',
          icon: <Create color={active === 'Create Assignments' ? THEME.primary : '#fff'} />,
          testid: 'create-assignments-menu',
          submenu: [
            {
              name: 'QA Assignment',
              icon: <Create color={active === 'QA Assignment' ? THEME.primary : THEME.black} />,
              link: '/home/qa/assignment/create',
              testid: 'qa-assignment-submenu',
            },

            {
              name: 'Calibration',
              icon: <Caliberate color={active === 'Calibration' ? THEME.primary : THEME.black} />,
              link: '/home/qa/assignment/calibrate',
              testid: 'calibration-submenu',
            },

            // {
            //   name: 'Grade the Grader',
            //   icon: (
            //     <Caliberate color={active === 'Grade the Grader' ? THEME.primary : THEME.black} />
            //   ),
            //   link: '/home/qa/assignment/grade',
            //   testid: 'grade-submenu',
            // },
          ],
        },

        {
          name: 'Scorecards',
          icon: <Idcard color={active === 'Scorecards' ? THEME.primary : '#fff'} />,
          link: '/home/qa/scorecard',
          testid: 'scorecards-menu',
        },
      ],
    },

    {
      header: 'Coaching',
      submenu: [
        {
          name: 'Share Feedback',
          icon: <Note color={active === 'Share Feedback' ? THEME.primary : '#fff'} />,
          link: '',
          testid: 'share-feedback-menu',
        },

        {
          name: 'Record Feedback',
          icon: <Camera color={active === 'Record Feedback' ? THEME.primary : '#fff'} />,
          link: '',
          testid: 'record-feedback-menu',
        },
        {
          name: 'Inbox',
          icon: <Inbox color={active === 'Inbox' ? THEME.primary : '#fff'} />,
          link: '/home/coaching?tab=inbox',
          testid: 'inbox-menu',
        },
        {
          name: 'Outbox',
          icon: <Outbox color={active === 'Outbox' ? THEME.primary : '#fff'} />,
          link: '/home/coaching?tab=outbox',
          testid: 'outbox-menu',
        },
      ],
    },
    {
      header: 'Settings',
      submenu: [
        {
          name: 'User Management',
          icon: <UserGearIcon color={active === 'User Management' ? THEME.primary : '#fff'} />,
          testid: 'user-management-menu',
          submenu: [
            {
              name: 'Users',
              icon: <User2 color={active === 'Users' ? THEME.primary : THEME.black} />,
              link: '/settings/manage-users',
              testid: 'users-submenu',
            },

            {
              name: 'Roles',
              icon: <Roles color={active === 'Roles' ? THEME.primary : THEME.black} />,
              link: '/settings/manage-roles',
              testid: 'roles-submenu',
            },

            {
              name: 'Groups',
              icon: <Usergroup color={active === 'Groups' ? THEME.primary : THEME.black} />,
              link: '/settings/manage-groups',
              testid: 'groups-submenu',
            },
          ],
        },
        {
          name: 'Reports',
          icon: <LineReportIcon color={active === 'Reports' ? THEME.primary : '#fff'} />,
          link: '/settings/reports',
          testid: 'reports-menu',
        },
      ],
    },
  ];
};
