import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  margin-bottom: 0;

  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.checked ? props.theme.secondary : '#ccc')};
  transition: 0.4s;
  border-radius: 20px;

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${(props) => (props.checked ? 'translateX(20px)' : 'translateX(0)')};
  }
`;

const ToggleButton = ({ checked, onChange }) => {
  return (
    <Wrapper>
      <input type="checkbox" checked={checked} onClick={onChange} />
      <Slider checked={checked} />
    </Wrapper>
  );
};

export default ToggleButton;
