import { Dropdown } from 'antd';
import { Avatar } from 'UI';
import { getCharName } from 'Helper/miscellaneous';
import DropdownMenu from './dropdownMenu';
import styled from 'styled-components';

export const DropDownText = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.black};
  > span {
    /* &:last-child {
      padding-left: 0.5rem;
    } */
  }
`;

const CaretDown = styled.span`
  margin-left: 8px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #5fc6f2;
`;

const AvatarDropdown = ({ user = '', showDropdown = false, setDropdownStatus, ...props }) => {
  return (
    <Dropdown
      open={showDropdown}
      menu={{ items: DropdownMenu(props), style: { minWidth: 130 } }}
      onOpenChange={setDropdownStatus}
      trigger={['click']}
      arrow
      placement="bottomRight">
      <DropDownText data-testid={'logged-in-user-avatar'}>
        <Avatar size={'small'}>{getCharName(user)}</Avatar>
        <CaretDown />
      </DropDownText>
    </Dropdown>
  );
};

export default AvatarDropdown;
