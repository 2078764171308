import { Link } from 'react-router-dom';

const DropdownMenu = ({ onLogout }) => [
  {
    key: 1,
    label: (
      <Link to={'/settings/profile'} data-testid={'my-profile-button'}>
        My Profile
      </Link>
    ),
  },
  {
    key: 2,
    label: (
      <a onClick={onLogout} data-testid={'logout-button'}>
        Logout
      </a>
    ),
  },
];

export default DropdownMenu;
