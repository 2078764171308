import * as React from 'react';

const SvgFlag = (props) => (
  <svg viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.441.098A.976.976 0 0 0 .13.362L.012.524l-.01 7.403C-.004 12 .004 16.042.02 16.913c.03 1.575.03 1.585.118 1.726.104.167.42.36.59.361.24 0 .533-.19.653-.425.076-.15.078-.205.1-3.354l.022-3.203 1.751-.797c5.77-2.625 9.305-4.246 9.904-4.54.117-.058.213-.114.213-.126 0-.01-.214-.116-.475-.233-.26-.118-.91-.413-1.443-.657a6106.96 6106.96 0 0 0-8.98-4.094l-.968-.44-.042-.317C1.401.346 1.283.164.966.053.742-.027.665-.02.441.098Z"
      fill={props?.color}
    />
    <path d="m1.5 3 8.499 3.5-8.496 4.006L1.499 3Z" fill={props?.color} />
  </svg>
);

export default SvgFlag;
