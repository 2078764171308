import * as React from 'react';

const LineReportIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clip-path="url(#clip0_4430_2)">
      <path
        d="M17.2501 18C14.5508 18 13.4781 14.8682 12.4406 11.8396C11.4574 8.96873 10.4401 6 8.2501 6C7.73867 6.03007 7.24778 6.21172 6.83997 6.5218C6.43216 6.83188 6.12589 7.25635 5.9602 7.74112L4.53857 7.26285C4.5763 7.15005 5.49145 4.5 8.2501 4.5C11.5119 4.5 12.7592 8.14103 13.8601 11.3534C14.7665 14 15.6226 16.5 17.2501 16.5C17.7615 16.4699 18.2524 16.2883 18.6602 15.9782C19.068 15.6681 19.3743 15.2436 19.54 14.7589L20.9616 15.2372C20.9239 15.35 20.0087 18 17.2501 18Z"
        fill={props?.color}
      />
      <path
        d="M3 21V12.75H4.5V11.25H3V1.5H1.5V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H22.5V21H3Z"
        fill={props?.color}
      />
      <path d="M7.5 11.25H6V12.75H7.5V11.25Z" fill={props?.color} />
      <path d="M10.5 11.25H9V12.75H10.5V11.25Z" fill={props?.color} />
      <path d="M16.5 11.25H15V12.75H16.5V11.25Z" fill={props?.color} />
      <path d="M19.5 11.25H18V12.75H19.5V11.25Z" fill={props?.color} />
      <path d="M22.5 11.25H21V12.75H22.5V11.25Z" fill={props?.color} />
    </g>
    <defs>
      <clipPath id="clip0_4430_2">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LineReportIcon;
