import * as React from 'react';

const SvgCreate = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.4286 5.67041H6.28571C5.6795 5.67041 5.09812 5.91123 4.66947 6.33988C4.24082 6.76853 4 7.34991 4 7.95612V18.2418C4 18.848 4.24082 19.4294 4.66947 19.8581C5.09812 20.2867 5.6795 20.5275 6.28571 20.5275H17.7143C18.3205 20.5275 18.9019 20.2867 19.3305 19.8581C19.7592 19.4294 20 18.848 20 18.2418V13.099"
      stroke={props?.color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7132 6.8143L18.8023 7.95716M19.9989 4.49088C20.3035 4.80591 20.4722 5.22804 20.4686 5.66628C20.465 6.10452 20.2893 6.52379 19.9795 6.83373L11.9989 14.8143L8.57031 15.9572L9.71317 12.5286L17.6983 4.47602C17.979 4.19314 18.3547 4.0244 18.7526 4.00245C19.1505 3.98049 19.5425 4.10688 19.8526 4.35716L19.9989 4.49088Z"
      stroke={props?.color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCreate;
