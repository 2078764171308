import Constants from './utils/constants';

export const awsConfig = ({ tenant }) => {
  return {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: tenant.aws_exports.identitypool_id,
      // REQUIRED - Amazon Cognito Region
      region: tenant.aws_exports.cognito_region,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: tenant.aws_exports.userpool_id,
      userPoolWebClientId: tenant.aws_exports.web_client_id,
    },
    API: {
      endpoints: [
        {
          name: 'MockCsAPI',
          endpoint: Constants.PROD_URL,
        },
      ],
    },
  };
};
