import * as React from 'react';

const SvgCross = (props) => (
  <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.69141 1.34375L9.00391 8.65625M9.00391 1.34375L1.69141 8.65625L9.00391 1.34375Z"
      stroke={props?.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCross;
