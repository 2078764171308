import React from 'react';
import styled from 'styled-components';
import { upperCase } from 'lodash';
import THEME from 'Constants/theme';

const ButtonWrapper = styled.button`
  line-height: 20px;
  border: none;
  font-size: 12px;
  padding: 10px 25px;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: ${(props) => props.theme.primaryFont};
  margin-left: 0px !important;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.3)' : props.bg)};
  /* box-shadow: 0px 10px 24px ${(props) => (props.disabled ? 'rgb(0 0 0 / 20%)' : '#827cfa54')}; */
  border-radius: ${(props) => props.theme.borderRadius};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const Button = ({ text, disabled = false, onClick, type = 'light' }) => {
  let bg = THEME.secondary;
  if (type === 'dark') bg = THEME.primary;

  return (
    <ButtonWrapper bg={bg} onClick={onClick} disabled={disabled}>
      {upperCase(text)}
    </ButtonWrapper>
  );
};

export default Button;
