import { Popover } from 'antd';
import THEME from 'Constants/theme';

const PopoverComp = ({ children, ...props }) => {
  return (
    <Popover
      overlayInnerStyle={{ borderRadius: THEME.borderRadius }}
      color={THEME.black}
      {...props}
      zIndex={1000000}>
      {children}
    </Popover>
  );
};

export default PopoverComp;

export { default as PopoverContent } from './popoverContent';
