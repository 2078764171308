import * as React from 'react';

const SvgPlus = (props) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.25 8.625H11.375V1.75C11.375 0.991 10.759 0.375 10 0.375C9.241 0.375 8.625 0.991 8.625 1.75V8.625H1.75C0.991 8.625 0.375 9.241 0.375 10C0.375 10.759 0.991 11.375 1.75 11.375H8.625V18.25C8.625 19.009 9.241 19.625 10 19.625C10.759 19.625 11.375 19.009 11.375 18.25V11.375H18.25C19.009 11.375 19.625 10.759 19.625 10C19.625 9.241 19.009 8.625 18.25 8.625Z"
      fill={props?.color}
    />
  </svg>
);

export default SvgPlus;
