import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Sider from './sider';
import Header from './header';
import { NAV_DATA } from './data';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'index';
import { useLogout } from 'API/auth';
import { filter } from 'lodash';
import queryString from 'qs';
import { SettingsContext } from 'Contexts/SettingsContext';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Body = styled.div`
  flex: 1;
  transition: 0.3s;
  overflow: auto;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 40px);
  background-color: #eff2f6;
  /* display: flex; */
`;

const Layout = ({ children }) => {
  const navItems = NAV_DATA('');
  const { leftNavCollapse, setSeftNavCollapse } = useContext(SettingsContext);
  const [activeNav, setActiveNav] = useState(NAV_DATA[0]?.submenu[0]?.name);
  const [avatarDropdown, setAvatarDropdown] = useState(false);

  const history = useHistory();
  const userContext = useContext(UserContext);

  const { refetch } = useLogout((bol) => {
    if (bol) {
      history.replace('/');
      window.location.reload();
    }
  });

  const onNavigation = (name, link) => {
    setActiveNav(name);
    if (link) history.push(link);
  };

  let pathname = history?.location?.pathname + history?.location?.search;

  useEffect(() => {
    if (navItems?.length) {
      navItems.forEach((data) => {
        let filtered = filter(data?.submenu, (e) => {
          if (
            e.link &&
            (e?.name === 'Assignments' ? pathname === e?.link : pathname?.includes(e?.link))
          )
            return true;
          if (
            e.submenu?.length &&
            e.submenu.filter((subChild) => pathname.includes(subChild.link))?.length
          )
            return true;
          return false;
        });
        if (filtered.length > 0) setActiveNav(filtered?.[0]?.name);
      });
    }
  }, [pathname, navItems]);

  const onNotesOpen = () => {
    let prevQuery = queryString.parse(history.location.search?.replace('?', ''));

    if (history.location.pathname === '/home/coaching') {
      history.push({
        pathname: history.location.pathname,
        search: `?${queryString.stringify({ ...prevQuery, notes: true })}`,
      });
    } else {
      history.push({
        pathname: '/home/coaching',
        search: `?${queryString.stringify({ notes: true })}`,
      });
    }
  };

  return (
    <Wrapper>
      <Sider
        collapsed={leftNavCollapse}
        setCollpased={setSeftNavCollapse}
        activeNav={activeNav}
        onNavigation={onNavigation}
        onNotesOpen={onNotesOpen}
      />
      <Body>
        <Header
          collapsed={leftNavCollapse}
          avatarDropdown={avatarDropdown}
          setAvatarDropdown={setAvatarDropdown}
          userName={userContext?.user}
          onLogout={refetch}
          tenantLogo={userContext?.tenantLogo ?? ''}
          tenantName={userContext?.tenant ?? ''}
        />
        <Container>
          <UserContext.Provider value={{ collapsed: leftNavCollapse, ...userContext }}>
            {children}
          </UserContext.Provider>
        </Container>
      </Body>
    </Wrapper>
  );
};

export default Layout;
