import React from 'react';

import Select from 'react-select';
import THEME from 'Constants/theme';

const customStyles = ({ isError }) => ({
  container: (provided, state) => ({
    ...provided,
    border: `1px solid ${isError ? 'red' : 'transparent'}`,
    borderRadius: THEME.borderRadius,
    fontSize: '13px',
    color: THEME.black,
    fontFamily: THEME.primaryFont,
  }),
});

const Dropdown = ({ isError = false, testid, ...props }) => {
  return (
    <Select
      {...props}
      styles={customStyles({ isError })}
      theme={(theme) => ({
        ...theme,
        borderRadius: THEME.borderRadius,
        colors: {
          ...theme.colors,
          primary25: THEME.lightPrimary,
          primary: THEME.primary,
        },
      })}
      components={{ IndicatorSeparator: () => null }}
      className={testid}
    />
  );
};

export default Dropdown;
