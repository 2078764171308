import React from 'react';
import { setup, isSupported } from '@loomhq/record-sdk';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PLAY_SVG from 'assets/images/media_play.svg';
import { Form } from 'react-bootstrap';
import { Spinner } from 'UI/Loading';
import { CenterWrapper } from 'UI/Wrapper';
import ErrorContainer from 'NewComponents/error';
import CLOSE_ICON from 'assets/images/close.svg';
import { notification } from 'antd';
import { useLoomToken } from 'API/common';
import { Request } from 'API/request';

import Modal, { Header, Body, Footer } from 'UI/Modal';
import { Button, Dropdown as CustomDropdown } from 'UI';
import { isEmpty, map } from 'lodash';

const { Group, Control } = Form;

const ORIGIN = window?.location?.origin;

let API_KEY = '8ea75731-b171-49e8-9872-6407a0f47d2b';
if (process.env.NODE_ENV !== 'development' && !ORIGIN.includes('staging')) {
  API_KEY = '0c9987ef-8a30-4ede-aadd-afc5c9e84df8';
}
const BUTTON_ID = 'loom-sdk-button';

const VideoLink = styled.a`
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  > img {
    padding-right: 0.5em;
  }
`;

const CustomFormControl = styled(Control)`
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;

  outline: red;
`;

const CustomFormGroup = styled(Group)`
  margin-bottom: 0;
`;

const CustomForm = styled(Form)`
  margin-top: 1em;
`;

const LoomApp = ({ children }) => {
  const [showModal, setModalStatus] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [agents, setAgents] = useState([]);
  const [message, setMessage] = useState('');
  const [allAgents, setAllAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const { data, isLoading } = useLoomToken();

  useEffect(() => {
    if (!isEmpty(data) && !isLoading) setupLoom();
  }, [data, isLoading]);

  const setupLoom = async () => {
    const { supported, error } = await isSupported();
    if (!supported) {
      console.warn(`Error setting up Loom: ${error}`);
      return;
    }
    const button = document.getElementById(BUTTON_ID);
    if (!button) return;

    const { configureButton } = await setup({ jws: data?.token });

    configureButton({
      element: button,

      hooks: {
        onInsertClicked: (data) => {
          setModalStatus(true);

          setVideoLink(data?.sharedUrl);
        },

        onComplete: (data) => {
          getAgentsList();
        },
      },
    });
  };

  const closeModal = () => setModalStatus(false);

  const getAgentsList = () => {
    setLoading(true);

    Request.get('myteam/allagents/')
      .then((data) => {
        setLoading(false);
        let temp = [];
        map(data, (d) => temp.push({ label: d.name, value: d.refkey }));
        setAllAgents(temp);
      })
      .catch((err) => {
        setLoading(false);
        let errorMessage = err?.response?.data?.error ?? { message: 'server error' };
        setError(errorMessage);
      });
  };

  const onSendData = (agents, link, message) => {
    setLoading(true);

    Request.post(`share-feedback/`, { agents, comment: message, video_link: link })
      .then((data) => {
        setLoading(false);
        closeModal();
        notification.success({
          message: `Video Sent`,
        });
      })
      .catch((err) => {
        let errorMessage = err?.response?.data;
        setLoading(false);
        setError(errorMessage);
      });
  };

  return (
    <>
      <div id={BUTTON_ID}>{children}</div>

      <Modal
        show={showModal}
        size="md"
        centered
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        animation={false}>
        <Header>
          <h2>Send Message to Team</h2>
          <a onClick={closeModal}>
            <img src={CLOSE_ICON} alt={'close'} />
          </a>
        </Header>

        {!isEmpty(error) ? (
          <CenterWrapper>
            <ErrorContainer error={error?.message ?? 'Something Went Wrong !!'} />
          </CenterWrapper>
        ) : (
          <>
            {loading ? (
              <CenterWrapper>
                <Spinner animation="border" />
              </CenterWrapper>
            ) : (
              <>
                <Body>
                  {videoLink && (
                    <VideoLink href={videoLink} target="_blank">
                      <img src={PLAY_SVG} alt={'play'} /> <span>Watch Video</span>
                    </VideoLink>
                  )}

                  <CustomDropdown
                    placeholder="Select Members"
                    isSearchable={true}
                    isMulti={true}
                    options={allAgents}
                    isClearable={true}
                    onChange={(e) => setAgents(e)}
                  />

                  <CustomForm onChange={(e) => setMessage(e.target.value)}>
                    <CustomFormGroup>
                      <CustomFormControl
                        as="textarea"
                        rows={6}
                        placeholder={'Comments (Optional)'}
                      />
                    </CustomFormGroup>
                  </CustomForm>
                </Body>
                <Footer>
                  <Button onClick={closeModal} text={'cancel'} />
                  <Button
                    onClick={() => {
                      let agentsValue = [];
                      map(agents, (agent) => agentsValue.push(agent.value));
                      onSendData(agentsValue, videoLink, message);
                    }}
                    disabled={agents.length === 0}
                    text={'Send'}
                    type="dark"
                  />
                </Footer>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default LoomApp;
